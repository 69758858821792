<template>
    <v-main>
     <v-container class="max-container">
            <CustomMenu :Itemselected="1"></CustomMenu>

            <v-btn color="primary" class="mb-2" @click="openDialog()">
            Crear Estudiante +
        </v-btn>

        <CreateStudent></CreateStudent>
        <ListStudents></ListStudents>
        </v-container>
    </v-main>
</template>
 
<script>
import eventBus from '@/common/EventBus';
import CustomMenu from '@/components/CustomMenu.vue';
import CreateStudent from '@/components/Students/CreateStudent.vue';
import ListStudents from '@/components/Students/ListStudents.vue';

export default {
    
    name: 'Student',
    components: {
        CustomMenu: CustomMenu,
        CreateStudent:CreateStudent,
        ListStudents:ListStudents
    },

    methods: {
        openDialog() {
            eventBus.dispatch('opendialog-createstudent')
        }
    },
}
</script>
 
<style scoped>
.max-container{
    max-width: 95%;
}
</style>