<template>
    <v-main>
        <v-dialog v-model="dialog" fullscreen hide-overlay transition="fade-transition">
            <v-card color="#fafcfd">
                <v-toolbar dark color="primary" dense flat outlined>
                    <v-toolbar-title></v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                        <v-btn icon dark @click="closeDialog">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </v-toolbar-items>
                </v-toolbar>

                <v-card class="mx-auto mt-3" max-width="700px" outlined :loading="loading">

                    <v-card-title class="grey lighten-4 font-weight-light mb-0 "> Crear un nuevo
                        Estudiante</v-card-title>
                    <v-card-subtitle class="grey lighten-4 font-weight-light  ">Debe crear un estudiante para asignar un
                        programa y generar la información de la venta</v-card-subtitle>
                    <v-divider></v-divider>

                    <v-form ref="form" v-model="valid" lazy-validation class="pt-3 pl-3 pr-3 pb-3 mt-2">
                        <v-card class="pl-3 pr-3" outlined>
                            <v-card-subtitle class="pl-0"> <strong style="color:blue">1.</strong> Ingrese la información
                                del
                                estudiante </v-card-subtitle>

                            <v-row no-gutters>

                                <v-col cols="12" sm="10" class="pb-0">
                                    <v-label>Email</v-label>
                                    <v-text-field v-model="student.email" :rules="max3caracter" required outlined dense
                                        class="mr-1" ></v-text-field>
                                </v-col>
                                <v-col cols="2" sm="2">

                                    <v-btn class="mt-5 ml-2" dark small fab color="primary" @click="getPayerHS()">
                                        <v-icon>mdi-magnify</v-icon>
                                    </v-btn>
                                </v-col>
                            </v-row>
                            <v-row no-gutters>
                                <v-col cols="12" sm="6">
                                    <v-label>DNI</v-label>
                                    <v-text-field v-model="student.dni" :rules="[dniIsValid]" required outlined dense
                                        class="mr-1"></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6">
                                    <v-label>Primer Nombre</v-label>
                                    <v-text-field v-model="student.name" :rules="max3caracter" required outlined dense
                                        class="mr-1"></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row no-gutters>
                                <v-col cols="12" sm="6">
                                    <v-label>Segundo Nombre</v-label>
                                    <v-text-field v-model="student.secondname" :rules="max3caracter" required outlined
                                        dense class="mr-1"></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" class="pb-0">
                                    <v-label>Primer Apellido</v-label>
                                    <v-text-field v-model="student.lastname" :rules="max3caracter" required outlined
                                        dense class="mr-1"></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row no-gutters>
                                <v-col cols="12" sm="6">
                                    <v-label>Segundo Apellido</v-label>
                                    <v-text-field v-model="student.secondlastname" :rules="max3caracter" required
                                        outlined dense class="mr-1"></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6">
                                    <v-label>Teléfono</v-label>
                                    <v-text-field v-model="student.phone" :rules="max3caracter" required outlined dense
                                        class="mr-1" prefix="+51"></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row no-gutters>
                                <v-col cols="12" sm="6" class="pb-0">
                                    <v-dialog ref="dialog" v-model="modal" :return-value.sync="date" persistent
                                        width="290px" dense>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-label>Fecha Nacimiento</v-label>
                                            <v-text-field v-model="computedDateFormatted" readonly v-bind="attrs"
                                                v-on="on" persistent-hint :rules="[isAdult]" outlined
                                                background-color="white" class="mr-1" dense></v-text-field>
                                        </template>
                                        <v-date-picker v-model="date" locale="clt-cl" :first-day-of-week="1" scrollable>
                                            <v-spacer></v-spacer>
                                            <v-btn text color="primary" @click="modal = false">
                                                Cancel
                                            </v-btn>
                                            <v-btn text color="primary" @click="$refs.dialog.save(date)">
                                                OK
                                            </v-btn>
                                        </v-date-picker>
                                    </v-dialog>
                                </v-col>
                                <v-col cols="12" sm="6">
                                    <v-label>Género</v-label>
                                    <v-select v-model="student.gender" :items="genero" required outlined
                                        background-color="white" dense class="mr-1"></v-select>
                                </v-col>
                            </v-row>

                        </v-card>
                    </v-form>

                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" class="mb-2" @click="createPayer()" :disabled="disablebtn">
                            Aceptar
                            <v-icon right dark> mdi-content-save </v-icon>
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-card>
        </v-dialog>
    </v-main>
</template>

<script>
/* eslint-disable */
import eventBus from '@/common/EventBus'
import StudentServices from '@/services/student.services';
import validacionesPeru from '@/validateRuc.js'

export default {
    name: 'creataStudent',

    data() {
        return {
            valid: false,
            dialog: false,
            loading: false,
            date: null,
            modal: false,
            dateFormatted: null,
            disablebtn:true,
            genero: ['Masculino', 'Femenino'],
            student: {
                hsId: 0,
                dni: 0,
                name: '',
                secondname: '',
                lastname: '',
                secondlastname: '',
                email: '',
                phone: '',
                birthdate: '',
                gender: '',
               
            },
            max3caracter: [
                (v) => !!v || "El campo es requerido",
                (v) => (v && v.length >= 3) || "El campo debe tener más de 3 caracteres",
            ],
            max5caracter: [
                (v) => !!v || "El campo es requerido",
                (v) =>
                    (v && v.length > 5) || "El campo debe tener más de 5 caracteres",
            ],



        }
    },
    mounted() {
        eventBus.on('opendialog-createstudent', () => {
            this.dialog = true
        })
    },

    methods: {

        closeDialog() {
            this.dialog = false
        },


        dniIsValid(value) {

            if(!undefined){
                const  isValid =  validacionesPeru.validaDNI(value);
                return isValid == true ? true : 'Formato no válido';
                
            }
            return true;
        },

        isAdult(value) {
            if (value != null && value.length > 0) {
                let dia = value.substring(0, 2);
                let mes = value.substring(3, 5);
                let anio = value.substring(6, 11);

                const hoy = new Date();
                const fechaNacimiento = new Date(`${anio}/${mes}/${dia}`)

                if (hoy.getTime() < fechaNacimiento.getTime())
                    return 'Debe seleccionar una fecha correcta';

                const edadmilisegundos = (hoy.getTime() - fechaNacimiento.getTime());
                const edad = (((edadmilisegundos / 1000) / 60) / 1440) / 365;

                if (edad < 18) return 'La edad mínima es 18 años';
                else return true;

            } else {
                return 'Debe seleccionar una fecha';
            }
        },
        formatDate(date) {
            if (!date) return null
            const [year, month, day] = date.split('-')
            return `${day}/${month}/${year}`
        },

        parseDate(date) {
            if (!date) return null
            const [month, day, year] = date.split('/')
            return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
        },


        createPayer() {
            this.loading = true;
            
            if (this.student.hsId > 0)
                StudentServices.postUpdateHS(this.student).then(() => { });

            StudentServices.postCreateStudents(this.student)
                .then(() => {
                    eventBus.dispatch('reload-liststudents');
                    this.loading = false;
                    this.dialog = false;

                })


            this.student = {}

        },

        getPayerHS() {

            this.student.email = this.student.email.trimStart().trimEnd();
            this.student.email = this.student.email.toLowerCase();

            StudentServices.getStundentsByDNIFromHS(this.student.email)
                .then((response) => {
                    this.student.hsId = response.data.results[0].id;
                    this.student.dni = response.data.results[0].properties.rut_pasaporte;
                    this.student.name = response.data.results[0].properties.firstname;
                    this.student.secondname = response.data.results[0].properties.segundo_nombre;
                    this.student.lastname = response.data.results[0].properties.lastname;
                    this.student.secondlastname = response.data.results[0].properties.segundo_apellido;
                    //this.student.phone = this.substringphone(response.data.results[0].properties.phone);
                    this.student.birthdate = response.data.results[0].properties.fecha_de_nacimiento__abierto_;
                    this.student.gender = response.data.results[0].properties.genero;

                    
                    this.disablebtn = response.data.results.length > 0 ? false : true;
                  

                });
        },

        substringphone(phone) {
            return phone.length >= 9 ? phone.substring(3, 12) : phone;
        }
    },
    /* eslint-disable */

    computed: {
        computedDateFormatted() {
            this.student.birthdate = this.formatDate(this.date);
            return this.formatDate(this.date)
        },
    }

}
</script>

<style scoped>
.v-label {
    color: #666161;
    font-weight: 400;
    font-size: 11pt
}

.v-text-field--outlined>>>fieldset {
    border-color: #dfe5ef
}

.v-application .transparent {
    border-color: rgb(255, 0, 0) !important;
}
</style>