<template>
  <div class="backlogin">
    <v-container>

      <v-container fluid pa-0>
        <v-row align="center" justify="center" style="height: 90vh">
          <v-col class="flex-column align-center">

            <!--front card login -->
            <v-card class="mx-auto" max-width="400" min-height="400" :loading="loading">
              <h3 class="font-weight-medium pt-6 ml-6">Bienvenido a P-Pay</h3>
              <p class="subtitle-2 font-weight-light mt-2  ml-6">Inicie sesión con su cuenta y comience la aventura.</p>

              <v-form ref="form" v-model="invalid" class="pl-4 pr-4 pt-6 ml-6 mr-6" @submit.prevent="handleLogin">

                <v-row>
                  <v-col>
                    <v-text-field v-model="user.username" label="Correo Electrónico" :rules="emailRules"
                      single-line></v-text-field>
                    <v-text-field v-model="user.password" :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                      :rules="[passRules.required, passRules.min]" label="Contraseña" :type="show ? 'text' : 'password'"
                      counter @click:append="show = !show" single-line></v-text-field>
                  </v-col>
                </v-row>


                <v-row align="center" justify="center">
                  <v-btn color="primary" depressed rounded class="text-capitalize" type="submit" :disabled="!invalid">
                    Iniciar Sesión
                  </v-btn>

                  {{ message }}

                </v-row>


              </v-form>


            </v-card>

            <!-- card register -->

          </v-col>
        </v-row>
      </v-container>



      <v-row>
        <v-col>
          <v-divider></v-divider>
          <p class="subtitle-2 text-center mt-2">Dudas o consultas escribir a : jfarias@enovus.com</p>
        </v-col>
      </v-row>

    </v-container>
  </div>
</template>

<script>
/* eslint-disable */
import User from '@/models/user';

export default {
  name: "Login",

  data() {
    return {
      show: false,
      invalid: false,
      loading: false,
      user: new User('', '', ''),
      email: "",
      message: "",
      password: "",
      passRules: {
        required: (value) => !!value || "requerido.",
        min: (v) => v.length >= 8 || "Min 8 characteres",
      },
      email: "",
      emailRules: [
        (v) => !!v || "E-mail es requerido",
        (v) => /.+@.+\..+/.test(v) || "E-mail no es valido",
      ],
    };
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    }
  },
  created() {
    if (this.loggedIn) {
      this.$router.push('/profile');
    }
  },
  methods: {
    handleLogin() {

      this.loading = true;
      this.$validator.validateAll().then(isValid => {
        if (!isValid) {
          this.loading = false;
          return;
        }

        if (this.user.username && this.user.password) {
          this.$store.dispatch('auth/login', this.user).then(
            () => {

              this.$router.push('/profile');
            },
            error => {
              this.loading = false;
              this.message =
                (error.response && error.response.data) ||
                error.message ||
                error.message.toString();
            }
          );
        }
      });
    }
  }
};
</script>
<style scoped >
.backlogin {
  width: 100%;
  height: 100%;
  /* background-color: #eb1ac1;*/
  background: linear-gradient(180deg, #00467a 50%, #FFFFFF 50%);
}
</style>
