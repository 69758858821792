<template>
  <v-container fluid pa-0>
    <v-row align="center" justify="center" style="height: 100vh">
      <v-col class="flex-column align-center">
        <!--front card login -->
        <v-card class="mx-auto text-center " max-width="400" min-height="450" elevation="10">
          <h2 class="text-center font-weight-light pt-10 pl-4 pr-4">
            Ingrese los datos solicitados para completar el registro
          </h2>

          <v-card-text>
            <v-form ref="form" v-model="invalid" class="pl-4 pr-4">
              <v-row>
                <v-col>
                  <v-text-field v-model="user.name" label="Nombre" single-line></v-text-field>

                  <v-text-field v-model="user.lastname" label="Apellido" single-line></v-text-field>

                  <v-text-field v-model="user.email" label="Email" :rules="emailRules" single-line></v-text-field>

                  <v-switch v-model="user.isorganizacion" inset>
                  
                     
                        <template v-slot:activator="{ on, attrs }">
                          <span v-bind="attrs" v-on="on">{{
                            user.isorganizacion == false
                            ? "Individual"
                            : "Escuela"
                          }}</span>
                        </template>

                   
                  </v-switch>

                  <transition name="fade">
                    <v-text-field v-show="user.isorganizacion" v-model="user.nombreOrg" label="Nombre Organización"
                      single-line></v-text-field>
                  </transition>

                  <v-text-field v-model="user.passwd" :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                    :rules="[passRules.required, passRules.min]" label="Password" :type="show ? 'text' : 'password'"
                    counter @click:append="show = !show" single-line></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>

          <v-card-actions class="justify-center">
            <v-btn color="primary" depressed rounded class="text-capitalize" type="submit" :disabled="!invalid">
              Registrar
            </v-btn>
          </v-card-actions>

          <v-card-text class="text-center" transition="slide-x-transition">
            <p class="subtitle-2">
              <router-link to="/login">Iniciar Sesión</router-link>
            </p>
          </v-card-text>
        </v-card>

        <!-- card register -->
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "LoginComponent",

  data() {
    return {
      invalid: false,
      show: false,
      password: "",
      passRules: {
        required: (value) => !!value || "requerido.",
        min: (v) => v.length >= 8 || "Min 8 characteres",
      },
      tiporegistro: false,
      email: "",
      emailRules: [
        (v) => !!v || "E-mail es requerido",
        (v) => /.+@.+\..+/.test(v) || "E-mail no es valido",
      ],

    user:{
      name:'',
      lastname:'',
      email:'',
      isorganizacion:false,
      nombreOrg:'',
      passwd:''
    }
    };
  },
  methods: {

  },
};
</script>
<style scoped>
.v-btn {
  width: 80%;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.v-card__text, .v-card__title {
  word-break: normal; /* maybe !important  */
}
</style>
