/* eslint-disable */
import Vue from "vue";
import VueRouter from "vue-router";
import Login from "../views/Login.vue";
import Register from "../views/Register.vue";
import Home from "../views/Home.vue";
import Profile from "../views/Profile.vue";
import BoardUser from "../views/BoardUser.vue"
import Programs from "../views/Programs.vue"
import Dues from "../views/Dues.vue"
import BoardAdmin from "../views/BoardAdmin.vue"
import Students from "../views/Students.vue"
import CheckOut from "../views/CheckOut.vue"
import ListDues from "../views/ListDues.vue"
import Successful from "../views/Success.vue"
import SuccessfulOC from "../views/SuccessOC.vue"
import Report from "../views/Report.vue"

Vue.use(VueRouter);
/* eslint-disable */
const routes = [
  {
    path: '/',
    name: 'login',
    component: Login
  },
  {
    path: '/home',
    component: Home
  },
  {
    path: "/login",
    name: "login",
    component: Login,
  },
  {
    path: "/register",
    name: "register",
    component: Register,
  },
  {
    path: "/profile",
    name: "profile",
    component: Profile,
  },
  {
    path: "/users",
    name: "boarduser",
    component: BoardUser,
  },

  {
    path: "/programs",
    name: "programs",
    component: Programs,
  },
  {
    path: "/dues",
    name: "dues",
    component: Dues,
  },
  {
    path: "/dashboard",
    name: "dashboard",
    component: BoardAdmin,
  },
  {
    path: "/students",
    name: "students",
    component: Students,
  },
  {
    path: "/list-dues",
    name: "ListDues",
    component: ListDues
  },

  {
    path: "/subscription/checkout/",
    name: "checkout",
    component: CheckOut,
  },
  {
    path:"/subscription/successful/",
    name:"successful",
    component:Successful
  },
  {
    path:"/subscription/successfulOC/",
    name:"SuccessfulOC",
    component:SuccessfulOC
  },
  {
    path:"/report",
    name:"report",
    component:Report
  }

];

const router = new VueRouter({
  mode: 'history',
  routes,
});

export default router;
