<template>
    <v-app>
        <!-- Menu izq-->
        <v-main>

            </v-main>
        </v-app>
</template>
  
<script>
export default {
    name: 'Profile',
    data: () => ({
       
      
    }),
    computed: {
        currentUser() {
            return this.$store.state.auth.user;
        }
    },
    mounted() {
        if (this.currentUser) {
            const findRole = (this.currentUser.roles.find((element) => element == 'ROLE_ADMIN') ? 'ROLE_ADMIN' : 'ROLE_USER');
            if (findRole == 'ROLE_ADMIN')
                this.$router.push('/users');
            else
                this.$router.push('/users');
        } else {
            this.$router.push('/login');
        }

    },

};
</script>

