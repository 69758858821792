import api from "./api";

class StudentServices {

  getAllStudents() {
    return api.get("payer/all");
  }

  getStundentsByDNIFromHS(email){
    return api.get(`payer/${email}`);
  }


  postCreateStudents(payer) {
    return api.post("payer/create", {
        hsId: payer.hsId,
        dni: payer.dni,
        name: payer.name,
        secondname: payer.secondname,
        lastname: payer.lastname,
        secondlastname: payer.secondlastname,
        email: payer.email,
        phone:payer.phone,
        birthdate: payer.birthdate,
        gender: payer.gender,
    });
  }

  postUpdateHS(payer) {
    return api.post("payer/updatehs", {
        hsId: payer.hsId,
        dni: payer.dni,
        name: payer.name,
        secondname: payer.secondname,
        lastname: payer.lastname,
        secondlastname: payer.secondlastname,
        email: payer.email,
        phone:payer.phone,
        birthdate: payer.birthdate,
        gender: payer.gender,
    });
  }



}
export default new StudentServices();

