<template>
  <v-main>
    <v-container class="max-container">
    <CustomMenu></CustomMenu>
    
      <h2>Dashboard</h2>
      <div class="my-6"></div>
      <v-row>
        <v-col cols="12" sm="4">
          <v-hover>
            <template v-slot:default="{ hover }">
              <v-card
                :elevation="hover ? 24 : 4"
                class="custom-card rounded-lg"
              >
                <v-card-actions pa-1>
                  <v-spacer></v-spacer>
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        pl-1
                        x-small
                        color="grey"
                        dark
                        v-bind="attrs"
                        v-on="on"
                        >mdi-help-circle</v-icon
                      >
                    </template>
                    <span
                      >Total de ingresos por los programas vendidos hasta la
                      fecha en pesos chilenos</span
                    >
                  </v-tooltip>
                </v-card-actions>
                <v-card-text>
                  <v-row align="center">
                    <v-col cols="8">
                      <v-card-subtitle>Ingresos Totales</v-card-subtitle>
                      <h4 class="text-h4 text--primary">$ 1.300.000</h4>
                    </v-col>
                    <v-col cols="4">
                      <v-icon class="mr-4" size="70px" color="#1C72D9"
                        >mdi-wallet</v-icon
                      >
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </template>
          </v-hover>
        </v-col>

        <v-col cols="12" sm="4">
          <v-hover>
            <template v-slot:default="{ hover }">
              <v-card
                :elevation="hover ? 24 : 4"
                class="custom-card rounded-lg"
              >
                <v-card-actions pa-1>
                  <v-spacer></v-spacer>
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        pl-1
                        x-small
                        color="grey"
                        dark
                        v-bind="attrs"
                        v-on="on"
                        >mdi-help-circle</v-icon
                      >
                    </template>
                    <span
                      >Cantidad de inscritos en todos los programas de
                      especialización</span
                    >
                  </v-tooltip>
                </v-card-actions>
                <v-card-text>
                  <v-row align="center">
                    <v-col cols="8">
                      <v-card-subtitle>Cantidad de Inscritos</v-card-subtitle>
                      <h4 class="text-h4 text--primary">1.000</h4>
                    </v-col>
                    <v-col cols="4">
                      <v-icon class="mr-4" size="70px" color="#1C72D9"
                        >mdi-account-group-outline</v-icon
                      >
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </template>
          </v-hover>
        </v-col>

        <v-col cols="12" sm="4">
          <v-hover>
            <template v-slot:default="{ hover }">
              <v-card
                :elevation="hover ? 24 : 4"
                class="custom-card rounded-lg"
              >
                <v-card-actions pa-1>
                  <v-spacer></v-spacer>
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        pl-1
                        x-small
                        color="grey"
                        dark
                        v-bind="attrs"
                        v-on="on"
                        >mdi-help-circle</v-icon
                      >
                    </template>
                    <span>El plan con más personas suscritas</span>
                  </v-tooltip>
                </v-card-actions>
                <v-card-text>
                  <v-row align="center">
                    <v-col cols="8">
                      <v-card-subtitle
                        >Plan con más suscriptores</v-card-subtitle
                      >
                      <h4 class="text-h4 text--primary">3 Cuotas</h4>
                    </v-col>
                    <v-col cols="4">
                      <v-icon class="mr-4" size="70px" color="#1C72D9"
                        >mdi-medal-outline</v-icon
                      >
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </template>
          </v-hover>
        </v-col>

        <v-col cols="12" sm="8" class="text-left">
          <v-hover>
            <template v-slot:default="{ hover }">
              <v-card
                :elevation="hover ? 24 : 4"
                class="custom-card rounded-lg"
              >
                <v-card-title pt-1>Planes Suscritos</v-card-title>
                <v-container>
                  <BarChart />
                </v-container>
              </v-card>
            </template>
          </v-hover>
        </v-col>

        <v-col cols="12" sm="4" class="text-left">
          <v-hover>
            <template v-slot:default="{ hover }">
              <v-card
                :elevation="hover ? 24 : 4"
                class="custom-card rounded-lg"
              >
                <v-card-title pt-1>Top Planes Suscritos</v-card-title>
                <v-container pa-3>
                  <PieChart />
                </v-container>
              </v-card>
            </template>
          </v-hover>
        </v-col>

        <v-col cols="12" sm="12" class="text-left">
          <v-hover>
            <template v-slot:default="{ hover }">
              <v-card
                :elevation="hover ? 24 : 4"
                class="custom-card rounded-lg"
              >
                <v-card-title pt-1>Ultimas Transacciones</v-card-title>
                <v-container pa-3>
                  <StudentsTransactions />
                </v-container>
              </v-card>
            </template>
          </v-hover>
        </v-col>

        <v-col cols="12" sm="4" class="text-left">
          <v-hover>
            <template v-slot:default="{ hover }">
              <v-card
                :elevation="hover ? 24 : 4"
                class="custom-card rounded-lg"
              >
                <v-card-title pt-1>Estado de Pagos</v-card-title>
                <v-container>
                  <StatusChart />
                </v-container>
              </v-card>
            </template>
          </v-hover>
        </v-col>

        <v-col cols="12" sm="8" class="text-left">
          <v-hover>
            <template v-slot:default="{ hover }">
              <v-card
                :elevation="hover ? 24 : 4"
                class="custom-card rounded-lg"
              >
                <v-card-title pt-1>Ventas</v-card-title>
                <v-container pa-3>
                  <LineChart />
                </v-container>
              </v-card>
            </template>
          </v-hover>
        </v-col>

        <v-col cols="12" sm="8" class="text-left">
          <v-hover>
            <template v-slot:default="{ hover }">
              <v-card
                :elevation="hover ? 24 : 4"
                class="custom-card rounded-lg"
              >
                <v-card-title pt-1>Programas de Especialización</v-card-title>
                <v-container pa-3>
                  <VerticalBarChart />
                </v-container>
              </v-card>
            </template>
          </v-hover>
        </v-col>

        <v-col cols="12" sm="4" class="text-left">
          <v-hover>
            <template v-slot:default="{ hover }">
              <v-card
                :elevation="hover ? 24 : 4"
                class="custom-card rounded-lg"
              >
                <v-card-title pt-1>Categorias </v-card-title>
                <v-container pa-3>
                  <ClusterPieChart />
                </v-container>
              </v-card>
            </template>
          </v-hover>
        </v-col>
      </v-row>
    </v-container>
    </v-main>

</template>

<script>
import CustomMenu from "@/components/CustomMenu.vue";
import { VRow } from "vuetify/lib";
import BarChart from "@/components/Charts/BarChart.vue";
import PieChart from "@/components/Charts/PieChart.vue";
import StudentsTransactions from "@/components/Charts/StudentsTransactions.vue";
import LineChart from "@/components/Charts/LineChart.vue";
import StatusChart from "@/components/Charts/StatusChart.vue";
import VerticalBarChart from "@/components/Charts/VerticalBarChart.vue";
import ClusterPieChart from "@/components/Charts/ClusterPieChart.vue";

export default {
  name: "dashboard",
  components: {
    CustomMenu,
    VRow,
    BarChart,
    PieChart,
    StudentsTransactions,
    LineChart,
    StatusChart,
    VerticalBarChart,
    ClusterPieChart,
  },
  data() {
    return {};
  },
  methods: {},
};
</script>

<style scoped>
.v-card__subtitle {
  padding: 0;
  padding-top: 0px;
  padding-bottom: 5px;
  color: #6a6e73;
}

.v-card__text {
  padding-top: 0px;
}
.custom-card {
  border-left: 2px solid #005cb9;
}

.max-container{
    max-width: 95%;
}

</style>