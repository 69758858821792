<template>
    <v-data-table
      :headers="headers"
      :items="desserts"
      :items-per-page="5"
      class="elevation-0"
    ><template v-slot:item.progreso="{ item }">
      <div>
        <v-progress-linear color="#00CCCA" :value="item.progreso"></v-progress-linear>
        {{ item.progreso }}%
      </div>
    </template>
  </v-data-table>
  </template>
  <script>
  export default {
    data () {
      return {
        headers: [
          {
            text: '  Estudiante',
            align: 'start',
            sortable: false,
            value: 'nombre',
          },
          { text: 'Fecha Transacción', value: 'fecha' },
          { text: 'Plan', value: 'plan' },
          { text: 'Programa de Especialización', value: 'programa' },
          { text: 'Monto Transacción', value: 'monto' },
          { text: 'Progreso de Pagos', value: 'progreso' },
        ],
        desserts: [
          {
            nombre: 'Florencia Araya',
            plan: 'Plan 1C',
            programa: ' Marketing Digital y Business Intelligence',
            monto: '$100.000 CLP',
            fecha: '10/01/2024',
            progreso: 20
          },
          {
            nombre: 'Marcelo',
            plan: 'Plan 3C-10',
            programa: 'Control de Gestión y Supply Chain',
            monto: '$100.000 CLP',
            fecha: '10/01/2024',
            progreso: 30
          },
          {
            nombre: 'Tomás',
            plan: 'Plan 5C',
            programa: 'Control de Gestión y Business Intelligence',
            monto: '$100.000 CLP',
            fecha: '10/01/2024',
            progreso: 40
          },
          {
            nombre: 'María',
            plan: 'Plan 5C-30',
            programa: 'Marketing Digital y Business Intelligence',
            monto: '$100.000 CLP',
            fecha: '09/01/2024',
            progreso: 40
          },
          {
            nombre: 'Rosario',
            plan: 'Plan 1C-30',
            programa: 'Control de Gestión y Business Intelligence',
            monto: '$100.000 CLP',
            fecha: '09/01/2024',
            progreso: 50
          },
          {
            nombre: 'Juan',
            plan: 'Plan 5C-10',
            programa: 'Control de Gestión y Business Intelligence',
            monto: '$100.000 CLP',
            fecha: '09/01/2024',
            progreso: 20
          },
          {
            nombre: 'Pedro',
            plan: 'Plan 1C-20',
            programa: 'Control de Gestión y Supply Chain',
            monto: '$100.000 CLP',
            fecha: '09/01/2024',
            progreso: 40
          },
          {
            nombre: 'Isabel',
            plan: 'Plan 5C',
            programa: 'Control de Gestión y Business Intelligence',
            monto: '$100.000 CLP',
            fecha: '09/01/2024',
            progreso: 40
          },
          {
            nombre: 'Hugo',
            plan: 'Plan 1C',
            programa: 'Control de Gestión y Business Intelligence',
            monto: '$100.000 CLP',
            fecha: '08/01/2024',
            progreso: 40
          },
          {
            nombre: 'José',
            plan: 'Plan 1C',
            programa: 'Control de Gestión y Business Intelligence',
            monto: '$100.000 CLP',
            fecha: '08/01/2024',
            progreso: 40

          },
        ],
      }
    },
  }
</script>