<template>
    <v-main>
        <v-container class="max-container">
            <CustomMenu></CustomMenu>
          
            <v-tabs>
                <v-tab>Links Pendientes</v-tab>
                <v-tab>Links con Pagos</v-tab>
              

                <v-tab-item>
                    <!-- content for tab 1 -->
                    <ListPlan typeview="pendiente" ></ListPlan>
                </v-tab-item>

                <v-tab-item>
                    <ListPlan typeview="con_pago" ></ListPlan>
                </v-tab-item>

               
            </v-tabs>
        </v-container>
    </v-main>
</template>

<script>

import CustomMenu from '@/components/CustomMenu.vue'
import ListPlan from '@/components/Plans/ListPlan.vue';
export default {

    name: 'listDues',

    components: {
        ListPlan: ListPlan,
        CustomMenu: CustomMenu,
    }
}
</script>

<style scoped>
.max-container{
    max-width: 95%;
}
</style>