<template>
  <v-container fluid class="backlogin">
    <RegisterComponent></RegisterComponent>
  </v-container>
</template>

<script>
/* eslint-disable */ 
import RegisterComponent from "@/components/RegisterComponent.vue";
export default {
  name: "Register",
  components: {
    RegisterComponent,
  },
};
</script>
<style scoped>
.backlogin {
  width: 100%;
  height: 100%;
  background-color: #0093e9;
  background-image: linear-gradient(160deg, #0093e9 0%, #80d0c7 100%);
}
</style>
