<template>
    <main class="bgcheckout" style="min-height: 100%;">
        <div class="boxcontainer">
            <v-img :src="require('@/assets/img/los-piura-andes.png')" class="logoretina"
                style="max-width: 550px; margin-left: 10px;"></v-img>
            <div class="contenido">
                <h2>UDEP Online : {{ plan.programname }}</h2>
                <h3>{{ plan.programdesc }}</h3>

                <div class="h_line"></div>
                <div style="margin-left: 5px; ">
                    <v-row>
                        <v-col cols="12" sm="4">
                            <v-list-item two-line>
                                <v-list-item-content style=" padding-left: 10px; color:#ffffff">
                                    <v-list-item-title> Precio del Programa : {{ plan.programprice
                                        }}</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-col>
                        <v-col cols="12" sm="2">
                            <v-list-item two-line>
                                <v-list-item-content
                                    style="border-left: 1px solid #0066ff; padding-left: 10px; color:#ffffff">
                                    <v-list-item-title> Cuotas: {{ plan.dues }}</v-list-item-title>

                                </v-list-item-content>
                            </v-list-item>
                        </v-col>
                        <v-col cols="12" sm="4">
                            <v-list-item two-line>
                                <v-list-item-content
                                    style="border-left: 1px solid #0066ff; padding-left: 10px; color:#ffffff">
                                    <v-list-item-title> Precio Cuota : {{ plan.amount }}</v-list-item-title>

                                </v-list-item-content>
                            </v-list-item>
                        </v-col>
                    </v-row>
                </div>
                <div>
                    <v-form ref="form" v-model="valid" lazy-validation>
                        <v-container>
                            <v-row>
                                <v-col cols="12" sm="12" md="6">
                                    <v-text-field v-model="plan.dni" label="DNI" filled dark dense :rules="nameRules"
                                        type="number" @keypress="filterNumber(event)"></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" sm="6" md="3">
                                    <v-text-field v-model="plan.fname" label="Nombre" filled dark dense
                                        :rules="nameRules"></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" md="3">
                                    <v-text-field v-model="plan.sname" label="Segundo Nombre" filled dark dense
                                        :rules="nameRules"></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" md="3">
                                    <v-text-field v-model="plan.lname" label="Apellido" filled dark dense
                                        :rules="nameRules"></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" md="3">
                                    <v-text-field v-model="plan.lsname" label="Segundo Apellido" filled dark dense
                                        :rules="nameRules"></v-text-field>
                                </v-col>
                            </v-row>


                            <v-row>
                                <v-col cols="12" sm="12" md="6">
                                    <v-text-field v-model="plan.email" label="Correo Electrónico" filled dark dense
                                        :rules="emailRules" disabled></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="12" md="6">
                                    <v-text-field v-model="plan.phone" label="Teléfono" filled dark dense
                                        :rules="nameRules"></v-text-field>
                                </v-col>

                                <v-col cols="12" sm="12" md="4">
                                    <v-select v-model="departamentoSelected" :items="departamentos" outlined required
                                        item-text="nombre_ubigeo" item-value="id_ubigeo" dense
                                        :rules="[v => !!v || 'Debe seleccionar el departamento']" filled dark
                                        label="Departamento" @change="changeDepartamento()"></v-select>
                                </v-col>
                                <v-col cols="12" sm="12" md="4">
                                    <v-select v-model="proviciaSelected" :items="provinciasbyubigeo" outlined required
                                        item-text="nombre_ubigeo" item-value="id_ubigeo" dense
                                        :rules="[v => !!v || 'Debe seleccionar la provincia']" filled dark
                                        label="Provincia" @change="changeProvincia()"></v-select>
                                </v-col>
                                <v-col cols="12" sm="12" md="4">
                                    <v-select v-model="distritoSelected" :items="distritobyubigeo" outlined required
                                        item-text="nombre_ubigeo" item-value="id_ubigeo" dense
                                        :rules="[v => !!v || 'Debe seleccionar el distrito']" filled dark
                                        label="Distrito"></v-select>
                                </v-col>

                                <v-col cols="12" sm="12" md="12">
                                    <v-text-field v-model="plan.address" label="Dirección" filled dark dense
                                        :rules="nameRules"></v-text-field>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col cols="12" sm="12" md="6">
                                    <v-file-input v-model="dnicopy" label="Subir DNI" filled dark dense
                                        :rules="nameRules" accept="image/*,.pdf"></v-file-input>
                                </v-col>
                                <v-col cols="12" sm="12" md="6">
                                    <v-file-input v-model="titulocopy" label="Subir Título" filled dark dense
                                        :rules="nameRules" accept="image/*,.pdf"></v-file-input>
                                </v-col>
                                <v-col>
                                    <v-checkbox v-model="terms" color="#0066ff" value="aceptado" dark
                                        :rules="[v => !!v || 'Debe aceptar los terminos para continar!']">
                                        <template v-slot:label>
                                            <div>
                                                Debe aceptar los

                                                <template>
                                                    <a target="_blank"
                                                        :href="terminos"
                                                        @click.stop>
                                                        Terminos y Condiciones
                                                    </a>
                                                </template>
                                                y el
                                                <a target="_blank"
                                                    :href="reglamento"
                                                    @click.stop>
                                                    Reglamento Academico
                                                </a>
                                            </div>
                                        </template>
                                    </v-checkbox>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-form>
                </div>
                <div class="text-center pb-10" style="width: 50%; text-align: center;  margin: auto;">
                    <v-btn rounded :loading="btnloading" color="#0066ff" dark block @click="validate()">
                        Inscribirse
                    </v-btn>
                </div>
                <div class="condiciones_footer pb-15">Los precios base que manejamos son en soles peruanos. El cargo
                    final podría variar de acuerdo a tu medio de pago, banco y el tipo de cambio del día, además de
                    estar sujeto a
                    impuestos
                    propios de cada país, los cuales serán asumidos por los usuarios finales. Los pagos en cuotas se
                    realizarán
                    automaticamente el mismo día del pago cada mes hasta completar la cantidad de cuotas.
                </div>
            </div>

        </div>

    </main>
</template>

<script>

/* eslint-disable */
import planServices from '@/services/plan.services'
import departamentos from '@/assets/geo/departamentos.json'
import provincias from '@/assets/geo/provincias.json'
import distritos from '@/assets/geo/distritos.json'


export default {
    name: 'CheckOut',
    data() {
        return {

            terminos: new URL('/src/assets/tyc/2024_05_03_Terminos_y_Condiciones_UDEP_UANDES_v1.pdf', import.meta.url).href,
            reglamento: new URL('/src/assets/tyc/2024_05_03_Reglamento_Academico_UDEP_UANDES_v1.pdf', import.meta.url).href,
            btnloading: false,
            valid: true,
            id: this.$route.query.gd,
            departamentos: [],
            provinciasbyubigeo: [],
            distritobyubigeo: [],
            plan: {
                programname: '',
                programdesc: '',
                programprice: '',
                dues: '',
                amount: '',
                dni: '',
                fname: '',
                lname: '',
                sname: '',
                lsname: '',
                email: '',
                phone: '',
                address: '',
                guidp: '',
                dnicopy: '',
                titulocopy: '',
                type_payment: '',
                departamento: '',
                provincia: '',
                distrito: '',
                hsid:'',
                titulofile:null,
            },
            departamentoSelected: '',
            proviciaSelected: '',
            distritoSelected: '',
            plantoken: '',
            uri_base_dlocal: process.env.VUE_APP_URI_CHECKOUT_PLAN,
            dnicopy: undefined,
            titulocopy: undefined,
            terms: false,

            nameRules: [
                v => !!v || 'Datos requeridos',
                v => (v && v.toString().length >= 3) || 'El campo debe tener al menos 3 characteres',
            ],
            emailRules: [
                v => !!v || 'E-mail is required',
                v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
            ],
        }
    },
    created() {
        this.getDataPlan()
    },
    mounted() {
        this.departamentos = departamentos;
    },
    methods: {

        changeDepartamento() {
            this.provinciasbyubigeo = provincias[this.departamentoSelected]
        },
        changeProvincia() {
            this.distritobyubigeo = distritos[this.proviciaSelected]
        },

        getDataPlan() {
            planServices.postplanbyguid(this.id).then((response) => {
                if (response.data) {
                    this.plan.programname = response.data.program.name,
                        this.plan.programdesc = response.data.description,
                        this.plan.dues = response.data.dues,
                        this.plan.amount = response.data.amount,
                        this.plan.programprice = response.data.priceprogram,
                        this.plan.dni = response.data.payer.dni,
                        this.plan.fname = response.data.payer.name,
                        this.plan.sname = response.data.payer.secondname,
                        this.plan.lname = response.data.payer.lastname,
                        this.plan.lsname = response.data.payer.secondlastname,
                        this.plan.email = response.data.payer.email,
                        this.plan.phone = response.data.payer.phone,
                        this.plan.address = response.data.payer.address,
                        this.plan.guidp = response.data.payer.guid,
                        this.plan.dnicopy = response.data.payer.dnidoc,
                        this.plan.titulocopy = response.data.payer.certificatedoc,
                        this.plan.type_payment = response.data.type_payment,
                        this.plan.hsid = response.data.payer.hsid

                    this.plantoken = this.uri_base_dlocal + response.data.plan_token
                }
            })
        },

        async validate() {
            if (this.$refs.form.validate()) {
                this.btnloading = true;
                this.startCheckOut();

            }
        },

        async startCheckOut() {

            this.plan.departamento = this.departamentos.filter(x => x.id_ubigeo == this.departamentoSelected)[0].nombre_ubigeo;
            this.plan.provincia = this.provinciasbyubigeo.filter(x => x.id_ubigeo == this.proviciaSelected)[0].nombre_ubigeo;
            this.plan.distrito = this.distritobyubigeo.filter(x => x.id_ubigeo == this.distritoSelected)[0].nombre_ubigeo;

            const DNI = await this.uploadDNI();

             const TITULO = new FormData()
              const xtension = '.' + this.titulocopy.name.split('.')[1];
              TITULO.append('file', this.titulocopy, 'TITULO-' + this.plan.dni + xtension)
              this.plan.titulofile = TITULO;

            if (DNI.status == 200) {
                this.uploadTitulo().then((rsupdTitulo) => {

                    if (rsupdTitulo.status == 200) {
                        this.plan.dnicopy = this.dnicopy.name;
                        this.plan.titulocopy = this.titulocopy.name;
                        planServices.postUpdatePayer(this.plan).then((response) => {
                            //debugger;
                            if (response.status == 200) {
                                if (this.plan.type_payment == 'OC') {
                                    this.$router.push({ path: '/subscription/successfulOC/' })
                                } else {
                                    window.location.href = this.plantoken
                                }
                            }
                        })
                    }
                });
            }
        },

        uploadDNI() {
            const DNI = new FormData()
            const xtension = '.' + this.dnicopy.name.split('.')[1];
            DNI.append('file', this.dnicopy, 'DNI-' + this.plan.dni + xtension)
            return planServices.postUploadFile(DNI)
        },

        uploadTitulo() {

            const TITULO = new FormData()
            const xtension = '.' + this.titulocopy.name.split('.')[1];
            TITULO.append('file', this.titulocopy, 'TITULO-' + this.plan.dni + xtension)
            return planServices.postUploadFile(TITULO)

        },


        //event key only numbers  -
        filterNumber(evt) {
            evt = (evt) ? evt : window.event;
            let expect = evt.target.value.toString() + evt.key.toString();
            if (!/^[-+]?[0-9]*\.?[0-9]*$/.test(expect)) {
                evt.preventDefault();
            } else {
                return true;
            }
        }
    },
}
</script>

<style>
.bgcheckout {
    background: url('@/assets/img/EdificioE.jpg');
    background-repeat: no-repeat;
    background-size: cover;
}

.boxcontainer {
    margin: auto;
    margin-top: 10px;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0.75));
    border-radius: 10px;
    width: 98%;
    text-align: center;
}



.h_line {
    border-bottom: 1px solid #0066ff;
    margin-right: 5%;
    margin-left: 5%;
    padding-bottom: 20px;

}

.logoretina {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
}

.contenido {
    margin-left: 5px;
    margin-right: 5px;
    color: #ffffff
}

.v-input--selection-controls {
    margin-top: 0;
    padding-top: 0;
}

.condiciones_footer {
    padding-left: 30px;
    padding-right: 30px;
    color: #dfdfdf;
}
</style>