<template>
    <v-main>
        <v-dialog v-model="dialog" fullscreen hide-overlay transition="fade-transition">
            <v-card color="#fafcfd">
                <v-toolbar dark color="primary" dense flat outlined>


                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                        <v-btn icon dark @click="closeDialog">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </v-toolbar-items>
                </v-toolbar>

                <v-card class="mx-auto mt-6" max-width="700px" outlined :loading="loading">

                    <v-card-title class="grey lighten-4 font-weight-light"> Crear un nuevo programa</v-card-title>
                    <v-divider></v-divider>
                    <v-form ref="form" v-model="valid" lazy-validation class="pt-3 pl-3 pr-3 pb-3 mt-2">
                        <v-label class="color:#0000ff">Nombre del programa</v-label>
                        <v-text-field v-model="program.name" :counter="80" :rules="max3caracter" required outlined
                            dense></v-text-field>
                        <v-row no-gutters>
                            <v-col cols="12" sm="5">
                                <v-label>Código del programa</v-label>
                                <v-text-field v-model="program.codeprogram" :rules="max3caracter" required outlined dense
                                    class="mr-1"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="7">
                                <v-label>Tipo de programa</v-label>
                                <v-select v-model="program.programTypeId" :items="programtypes"
                                    :rules="[v => !!v || 'Debe seleccionar un tipo de programa']" required item-text="name"
                                    item-value="id" outlined class="ml-1 " dense></v-select>
                            </v-col>
                        </v-row>

                        <v-row no-gutters>

                            <v-col cols="12" sm="4">
                                <v-label>Moneda</v-label>
                                <v-text-field v-model="program.currency" :rules="max5caracter" required outlined
                                    class="mr-1" disabled dense></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="4">
                                <v-label>Precio PEN</v-label>
                                <v-text-field v-model="program.price" :rules="max3caracter" required outlined class="ml-1"
                                    type="number" dense></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="4">
                                <v-label>Precio CLP</v-label>
                                <v-text-field v-model="program.priceCLP" :rules="max5caracter" required outlined
                                    class="ml-1" type="number" dense></v-text-field>

                            </v-col>
                            <p class="text-right text--disabled font-weight-light subtitle-2 mt-0 pt-0">
                                * Los precios ingresados en el programa son de referencia. ( precio real corresponde al
                                plan)
                            </p>
                        </v-row>

                        <v-label>Descripción del Programa</v-label>
                        <v-textarea v-model="program.description" outlined rows="2"></v-textarea>
                    </v-form>

                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" class="mb-2" @click="validate()">
                            Aceptar
                            <v-icon right dark> mdi-content-save </v-icon>
                        </v-btn>
                    </v-card-actions>
                </v-card>

            </v-card>
        </v-dialog>
    </v-main>
</template>
<script>

import programServices from '@/services/program.services'
import eventBus from '@/common/EventBus'


export default {

    name: "CreatePrograms",
    props: {
        programtypes: { type: [], default: 0 },

    },

    data() {
        return {
            valid: false,
            dialog: false,
            loading: false,
            program: {
                name: '',
                description: '',
                codeprogram: '',
                currency: 'PEN',
                price: 0,
                priceCLP: 0,
                programTypeId: 0,
                createby: 0
            },
            max3caracter: [
                (v) => !!v || "El campo es requerido",
                (v) =>
                    (v && v.length >= 3) || "El campo debe tener más de 3 caracteres",
            ],
            max5caracter: [
                (v) => !!v || "El campo es requerido",
                (v) =>
                    (v && v.length > 5) || "El campo debe tener más de 5 caracteres",
            ],
        }
    },
    mounted() {
        eventBus.on('opendialog-createprogram', () => {
            this.dialog = true
        })

    },
    methods: {
        closeDialog() {
            this.dialog = false
        },

        validate() {
            if (this.$refs.form.validate())
                this.createProgram();
        },

        createProgram() {
            this.loading = true;
            this.program.createby = this.$store.state.auth.user.id
            programServices.postCreateProgram(this.program)
                
            .then(() => {
                    eventBus.dispatch('reloadgrod-listprogram');
                    this.loading = false;
                    this.dialog = false;
                    this.program = {}
                    this.program.currency = 'PEN'
                },
                    error => {
                        console.log(error)
                    })
        }

    },
}
</script>
<style scoped>
.v-label {
    color: #666161;
    font-weight: 400;
    font-size: 11pt
}

.v-text-field--outlined>>>fieldset {
    border-color: #dfe5ef
}

.v-application .transparent {
    border-color: rgb(255, 0, 0) !important;
}
</style>