<template>
  <v-sheet width='100%' elevation='0' outlined rounded>
    <v-row class=' mr-4'>

      <v-col cols='6' sm='4'>
        <v-text-field v-model='search' append-icon='mdi-magnify' label='Search' single-line hide-details
          class='pb-3 ml-4'></v-text-field>

      </v-col>
      <v-spacer></v-spacer>
    </v-row>
    <v-divider></v-divider>

    <v-data-table :headers='headers' :items='programs' :loading='loading' :search='search'  :custom-filter='customSearch' loading-text='Cargando información...'>
      loading-text='Cargando información...'>
      <template v-slot:item='row'>
        <tr>
          <td>{{ row.item.name }}</td>
          <td>{{ row.item.codeProgram }}</td>
          <td>{{ row.item.currency }}</td>
          <td>{{ row.item.price }}</td>
          <td>{{ row.item.priceCLP }}</td>
          <td>{{ row.item.program_type.name }}</td>
          <td>
            <v-btn elevation='2' icon @click='editUer(row.item)' color='primary' class='mr-1'>
              <v-icon> mdi-pencil</v-icon></v-btn>

            <v-btn elevation='2' icon @click='editUer(row.item)' color='secondary'>
              <v-icon> mdi-magnify</v-icon></v-btn>
          </td>
        </tr>
      </template>
    </v-data-table>
  </v-sheet>
</template>
<script>
import eventBus from '@/common/EventBus';
import programServices from '@/services/program.services';
import CustomSearch from '@/services/util';

export default {
  name: 'adminPrograms',
  props: {
    programtypes: { type: [], default: 0 }
  },
  data() {
    return {
      programs: [],
      search: '',
      loading: true,
      dialog: false,
      headers: [
        { text: 'Nombre del programa', align: 'start', sortable: true, value: 'name' },
        { text: 'Código', sortable: false },
        { text: 'Moneda', sortable: false },
        { text: 'Precio', sortable: false },
        { text: 'Precio CLP', sortable: false },
        { text: 'Tipo Programa', sortable: true },
        { text: 'Opciones', align: 'center', sortable: false },
      ],
      nameRules: [
        (v) => !!v || 'El campo es requerido',
        (v) =>
          (v && v.length >= 3) || 'El campo debe tener más de 3 caracteres',
      ],
    };
  },
  mounted() {
    this.getAllPrograms();

    eventBus.on('reloadgrod-listprogram', () => {
        this.getAllPrograms();
    })
  },

  methods: {

    openDialog() {
      this.dialog = true;
    },

    getAllPrograms() {
      programServices.getAllProgramas().then((response) => {
        this.programs = response.data;
        this.loading = false;
      });
    },

     customSearch (value, search, item) {
      return CustomSearch(value, search, item)
     } 
  },
};

</script>
<style scoped>
.v-container {
  max-width: 95%;
}
</style>