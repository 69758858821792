<template>
    <v-main>
        <v-container class="max-container">
            <CustomMenu></CustomMenu>

            <v-stepper v-model="e1">
                <v-stepper-header>
                    <v-stepper-step :complete="e1 > 1" step="1">
                        <span>
                            Seleccione un Estudiante
                        </span>
                    </v-stepper-step>

                    <v-divider></v-divider>

                    <v-stepper-step :complete="e1 > 2" step="2">
                        Seleccione un Programa
                    </v-stepper-step>
                    <v-divider></v-divider>
                    <v-stepper-step :complete="e1 > 2" step="3">
                        Ingrese Información del pago
                    </v-stepper-step>
                </v-stepper-header>

                <v-stepper-items>
                    <v-stepper-content step="1">
                        <SelectStudents></SelectStudents>
                        <v-divider></v-divider>
                        <v-col class="text-right">
                            <v-btn color="primary" @click="goToSecondStep()" class="mt-5">
                                Continue >
                            </v-btn>
                        </v-col>
                    </v-stepper-content>

                    <v-stepper-content step="2">
                        <SelectProgram></SelectProgram>
                        <v-divider></v-divider>
                        <v-col class="text-right">
                            <v-btn color="primary" @click="goToThirdStep()">
                                Continue >
                            </v-btn>
                            <v-btn text @click="e1 = 1">
                                Volver
                            </v-btn>
                        </v-col>
                    </v-stepper-content>

                    <v-stepper-content step="3">
                        <CreatePlan></CreatePlan>
                        <v-col class="text-right">
                            <v-divider></v-divider>
                            <v-btn text @click="goBackToSecond()">
                                Volver
                            </v-btn>
                        </v-col>
                    </v-stepper-content>

                </v-stepper-items>
            </v-stepper>
            <v-slide-y-transition>
                <v-alert color="red" dense elevation="2" type="info" class="mt-2 alertposition" max-width="500px"
                    v-if="msgerror.length > 3" align-center> {{ msgerror }}</v-alert>
            </v-slide-y-transition>
        </v-container>
    </v-main>
</template>

<script>

import CustomMenu from '@/components/CustomMenu.vue'
import programServices from '@/services/program.services'
import SelectStudents from '@/components/sales/SelectStudents.vue'
import SelectProgram from '@/components/sales/SelectProgram.vue'
import CreatePlan from '@/components/sales/CreatePlan.vue'
import eventBus from '@/common/EventBus'


export default {
    name: 'plans',
    components: {
        CustomMenu: CustomMenu,
        SelectStudents: SelectStudents,
        SelectProgram: SelectProgram,
        CreatePlan: CreatePlan
    },

    data() {

        return {
            e1: 1,
            programs: [],
            msgerror: '',
            plan: {
                name: '',
                description: '',
                country: '',
                currency: '',
                amount: 0,
                priceprogram: 0,
                dues: 0,
                createby: 0,
                programId: 0
            }

        }
    },

    methods: {
        getAllPrograms() {
            programServices.getAllProgramas().then((response) => {
                this.programs = response.data;
            })
        },

        goToSecondStep() {
            // eslint-disable-next-line 
        debugger
            if (localStorage.getItem('studentls')) {
                try {
                    const student = JSON.parse(localStorage.getItem('studentls'));
                    if (student.name.length > 0) {
                        this.e1 = 2;
                        this.msgerror = '';
                    }

                } catch (e) {
                    localStorage.removeItem('studentls');
                }
            } else {
                this.msgerror = 'Debe seleccionar un Estudiante para continuar'
                setTimeout(() => {
                    this.msgerror = ''
                }, 2500)
            }
        },

        goToThirdStep() {
            if (localStorage.getItem('programls')) {
                try {
                    const program = JSON.parse(localStorage.getItem('programls'));
                    if (program.name.length > 3) {
                        this.e1 = 3;
                        this.msgerror = '';
                        eventBus.dispatch('set-create-program');
                    }
                } catch (e) {
                    localStorage.removeItem('programls');
                }
            } else {
                this.msgerror = 'Debe seleccionar un Programa para continuar'
                setTimeout(() => { this.msgerror = '' }, 2500)
            }
        },

        goBackToSecond(){
            
            this.e1 = 2;
        }
    },
    mounted() {
        this.getAllPrograms()

        if (localStorage.getItem('studentls'))
            localStorage.removeItem('studentls')

        if (localStorage.getItem('programls'))
            localStorage.removeItem('programls')
    }
}

</script>

<style scoped>
.alertposition {
    position: fixed;
    /* posición fija en la ventana de tu navegador */
    top: 10px;
    /* distancia en px desde la parte superior */
    right: 10px;
    /* distancia en px desde la parte derecha */
}

.max-container{
    max-width: 95%;
}
</style>