<template>
  <v-container fluid>
    <v-sheet width="100%" elevation="0" outlined rounded>
      <v-data-table :headers="headers" :items="plans" :loading="loading" loading-text="Cargando información..."
        :items-per-page="30">
        <template v-slot:item="row">

          <tr>
            <td>
              {{ plans.indexOf(row.item) + 1 }}
            </td>
            <td>{{ row.item.plan_name }}</td>
            <td>{{ row.item.payer_name }}</td>
            <td>
              <v-icon v-if="row.item.totpay == 0" color="red">mdi-minus-circle-outline</v-icon>
              <v-icon v-else color="green">mdi-check</v-icon>
            </td>
            <td>{{ row.item.amount }}</td>
            <td>{{ row.item.dues }}</td>
            <td>{{ row.item.priceprogram }}</td>
            <td>{{ row.item.type_payment }}</td>
            <td>{{ row.item.createby_name }} </td>
            <td> <a :href="urlCheckOut + row.item.guid" target="_blank"> LINK</a> </td>
            <td>
              <v-btn elevation='2' icon @click='detailsDues(row.item.guid)' color='primary' class='mr-1'>
                <v-icon> mdi-magnify</v-icon></v-btn>
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-sheet>
    <div class="text-center">
      <v-dialog v-model="dialog" width="900">
        <v-card>
          <v-card-title class="text-h6 grey lighten-2 mb-5">
            Detalle del Plan : {{ this.plan.name }}
          </v-card-title>

          <v-card-text>
            <v-label class="font-weight-black">Datos del Estudiante</v-label>
            <v-divider></v-divider>
            <v-row class="mt-1">
              <v-col cols="12" sm="4">
                <v-label class="font-weight-bold">DNI : </v-label>
                <v-label>{{ student.dni }}</v-label>
              </v-col>
              <v-col cols="12" sm="8">
                <v-label class="font-weight-bold">Nombre : </v-label>
                <v-label> {{ student.name }} {{ student.secondname }} {{ student.lastname }} {{ student.secondlastname
                  }}
                </v-label>
              </v-col>
              <v-col cols="12" sm="4">
                <v-label class="font-weight-bold">Fono : </v-label>
                <v-label> {{ student.phone }}</v-label>
              </v-col>
              <v-col cols="12" sm="8">
                <v-label class="font-weight-bold">Email : </v-label>
                <v-label> {{ student.email }}</v-label>
              </v-col>
              <v-col cols="12" sm="8">
                <v-label class="font-weight-bold">Dirección : </v-label>
                <v-label> {{ student.address }}</v-label>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-text>
            <v-label class="font-weight-black ">Datos del Plan</v-label>
            <v-divider></v-divider>

            <v-row class="mt-3" no-gutters>
              <v-col cols="12" sm="2">
                <v-label class="font-weight-bold">Moneda : </v-label>
                <v-label>PEN</v-label>
              </v-col>
              <v-col cols="12" sm="3">
                <v-label class="font-weight-bold">Precio Programa : </v-label>
                <v-label> {{ plan.priceprogram }}</v-label>
              </v-col>

              <v-col cols="12" sm="3">
                <v-label class="font-weight-bold">Cantidad Cuotas : </v-label>
                <v-label> {{ plan.dues }}</v-label>
              </v-col>

              <v-col cols="12" sm="3">
                <v-label class="font-weight-bold">Valor Cuota : </v-label>
                <v-label> {{ plan.amount }}</v-label>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="3">
                <v-label class="font-weight-bold">Descuento : </v-label>
                <v-label> {{ plan.discount }}%</v-label>
              </v-col>
              <v-col cols="12" sm="3">
                <v-label class="font-weight-bold">Tipo Pago : </v-label>
                <v-label> {{ plan.type_payment }}</v-label>
              </v-col>
              <v-col cols="12" sm="9">
                <v-label class="font-weight-bold">Descripción : </v-label>
                <v-label> {{ plan.desc }}</v-label>
              </v-col>
            </v-row>
          </v-card-text>

          <v-card-text>



            <v-data-table class="mt-4" style="width: 98%;" :headers="headers_payments" :items="payments"
              :loading="loading" loading-text="Cargando información...">
              <template v-slot:item="row">
                <tr>
                  <td>
                    {{ payments.indexOf(row.item) + 1 }}
                  </td>
                  <td>S/ {{ row.item.price }} </td>
                  <td>{{ formatDate(row.item.createdAt) }}</td>
                </tr>
              </template>
            </v-data-table>

          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="dialog = false">
              Cerrar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>

  </v-container>

</template>
<script>
import eventBus from "@/common/EventBus";
import planServices from "@/services/plan.services";
import dayjs from 'dayjs';

export default {
  name: "ListPlan",
  props: {
    typeview: {
            type: String,
            default: 'pendiente'
        }
  },
  data() {
    return {
      plans: [],
      programs: [],
      plan: {
        name: '',
        desc: '',
        dues: 0,
        priceprogram: '',
        amount: '',
      },
      program: {
        name: '',
        cod: '',
      },
      student: {
        dni: '',
        name: '',
        secondname: '',
        lastname: '',
        secondlastname: '',
        email: '',
        phone: '',
        address: '',
      },
      payments: [],
      loading: true,
      dialog: false,
      urlCheckOut: process.env.VUE_APP_URL_CHECKOUT,
      headers: [
        { text: "N°", sortable: false },
        { text: "Nombre del  plan", align: "start", sortable: true, value: "name" },
        { text: "Estudiante", align: "start", sortable: false },
        { text: "Pago", sortable: false },
        { text: "Valor cuota", sortable: false },
        { text: "Cuotas", sortable: false },
        { text: "Precio del programa", sortable: false },
        { text: "Tipo de Pago", sortable: false },
        { text: "Creado por", sortable: false },
        { text: "Url Pago", sortable: false },
        { text: "Detalle", sortable: false },

      ],
      headers_payments: [
        { text: "N°", sortable: false },
        { text: "Monto Pagado", align: "start", sortable: false },
        { text: "Fecha de Pago", align: "start", sortable: false },


      ],
      nameRules: [
        (v) => !!v || "El campo es requerido",
        (v) =>
          (v && v.length >= 3) || "El campo debe tener más de 3 caracteres",
      ],
    };
  },
  mounted() {
    this.getAllPlan();
    eventBus.on('reloadgrod-listplan', () => {
      //  this.getAllPrograms();
    })
  },

  methods: {
    openDialog() {
      this.dialog = true;
    },

    getAllPlan() {
      planServices.getAllPlan().then((response) => {
        if (this.typeview == 'pendiente')
          this.plans = response.data.Plan.filter((p) => p.totpay == 0);
        else if (this.typeview == 'con_pago')
          this.plans = response.data.Plan.filter((p) => p.totpay > 0);

        this.loading = false;
      });
    },
    detailsDues(guid) {
      planServices.getPlanByGuid(guid).then((response) => {

        this.plan.name = response.data.rsplan.name;

        this.student.dni = response.data.rsplan.payer.dni;
        this.student.name = response.data.rsplan.payer.name;
        this.student.secondname = response.data.rsplan.payer.secondname;
        this.student.lastname = response.data.rsplan.payer.lastname;
        this.student.secondlastname = response.data.rsplan.payer.secondlastname;
        this.student.phone = response.data.rsplan.payer.phone;
        this.student.email = response.data.rsplan.payer.email;
        this.student.address = response.data.rsplan.payer.address;

        this.plan.desc = response.data.rsplan.description;
        this.plan.dues = response.data.rsplan.dues;
        this.plan.priceprogram = response.data.rsplan.priceprogram;
        this.plan.discount = response.data.rsplan.discount;
        this.plan.amount = response.data.rsplan.amount;
        this.plan.type_payment = response.data.rsplan.type_payment;
        this.payments = response.data.respayment
      })
      this.dialog = true;

    },
    /* eslint-disable */
    formatDate(dateString) {
      const date = dayjs(dateString);
      // Then specify how you want your dates to be formatted
      return date.format('DD/MM/YYYY');
    }
  },
};
</script>
<style scoped>
.v-data-table {
  max-width: 100%
}
</style>