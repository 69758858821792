<template>
  <v-container>
    <v-sheet width="100%" elevation="0" outlined rounded>
      <v-row class=" mr-4">
        <v-col cols="6" sm="4">
          <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details
            class="pb-3 ml-4"></v-text-field>
        </v-col>
        <v-spacer></v-spacer>
      </v-row>
      <v-divider></v-divider>

      <v-data-table :headers="headers" :items="programs" :loading="loading" :search="search" :custom-filter="customSearch"
        loading-text="Cargando información...">
        <template v-slot:item="row">
          <tr>
            <td>{{ row.item.name }}</td>
            <td>{{ row.item.codeProgram }}</td>
            <td>{{ row.item.currency }}</td>
            <td>{{ row.item.price }}</td>
            <td>{{ row.item.program_type.name }}</td>
            <td style="text-align:center">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" elevation="2" icon @click="addProgrma(row.item)" color="primary"
                    class="mr-1">
                    <v-icon> mdi-plus</v-icon></v-btn>
                </template>
                <span>Seleccionar</span>
              </v-tooltip>
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-sheet>
    <v-expand-transition>
      <v-card class="mx-auto mt-3 transition-fast-in-fast-out v-card--reveal" max-width="100%" outlined v-if="reveal">
        <v-card-text>
          <p class="body-1 pb-0 mb-2">Programa seleccionado</p>
          <v-divider></v-divider>
          <v-simple-table class="mt-1">
            <tbody>
              <tr>
                <td><strong>Nombre del programa:</strong> {{ selectedProgram.name }} </td>
                <td><strong>Código:</strong>{{ selectedProgram.codeProgram }}</td>
                <td><strong>Tipo Programa:</strong> {{ selectedProgram.programType }}</td>
                <td><strong>Moneda:</strong> {{ selectedProgram.currency }}</td>
                <td><strong>Precio:</strong> {{ selectedProgram.price }}</td>
                <td>
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn v-bind="attrs" v-on="on" elevation="2" icon @click="removeProgram()" color="error"
                        class="mr-1">
                        <v-icon> mdi-minus</v-icon></v-btn>
                    </template>
                    <span>Eliminar selección </span>
                  </v-tooltip>
                </td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-card-text>
      </v-card>
    </v-expand-transition>
  </v-container>
</template>
<script>

import programServices from "@/services/program.services";
import CustomSearch from '@/services/util';

/* eslint-disable */
// eslint-disable-next-line

export default {
  name: "SelectProgram",

  data() {
    return {
      programs: [],
      search: '',
      selectedProgram: {
        id: 0,
        name: '',
        description: '',
        codeProgram: '',
        currency: '',
        country: '',
        price: '',
        priceCLP: '',
        programType: ''
      },
      reveal: false,
      loading: true,
      headers: [
        { text: "Nombre del programa", align: "start", sortable: true, value: "name" },
        { text: "Código", sortable: false },
        { text: "Moneda", sortable: false },
        { text: "Precio", sortable: false },
        { text: "Tipo Programa", sortable: true },
        { text: "Opciones", align: "center", sortable: false },
      ],
    };
  },
  mounted() {
    this.getAllPrograms();

  },

  methods: {
      getAllPrograms() {

        programServices.getAllProgramas().then((response) => {
          this.programs = response.data;
          this.loading = false;
        });

      },

      addProgrma(program) {

        this.selectedProgram = {}
        localStorage.removeItem('programls');
        
        this.reveal = false;
        this.selectedProgram.id = program.id;
        this.selectedProgram.name = program.name;
        this.selectedProgram.description = program.description;
        this.selectedProgram.codeProgram = program.codeProgram;
        this.selectedProgram.currency = program.currency;
        this.selectedProgram.country = program.country;
        this.selectedProgram.price = program.price;
        this.selectedProgram.priceCLP = program.priceCLP;
        this.selectedProgram.programType = program.program_type.name;

        const programls = {
          id: program.id,
          name: program.name,
          description: program.description,
          codeProgram: program.codeProgram,
          currency: program.currency,
          country: program.country,
          price: program.price,
          priceCLP: program.priceCLP,
          programType: program.program_type.name
        };

        localStorage.setItem('programls', JSON.stringify(programls));
        this.reveal = true;
      },

      removeProgram() {
        this.selectedProgram = {}
        localStorage.removeItem('programls');
        this.reveal = false;
      },

      customSearch(value, search, item) {
        return CustomSearch(value, search, item)
      }
  },
};
</script>
<style scoped></style>