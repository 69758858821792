

<template>
    <v-container>
        <v-sheet width="100%" outlined rounded>
            <v-row class=' mr-4'>
                <v-col cols='6' sm='4'>
                    <v-text-field v-model='search' append-icon='mdi-magnify' label='Search' single-line hide-details
                        class='pb-3 ml-4'></v-text-field>
                </v-col>
                <v-spacer></v-spacer>
            </v-row>
            <v-divider></v-divider>
            <v-data-table :headers="headers" :items="students" :loading="loading" :search="search"
                :custom-filter="customSearch" loading-text="Cargando información...">
                
                <template v-slot:item="row">
                    <tr>
                        <td>{{ row.item.dni }}</td>
                        <td>{{ row.item.name }}</td>
                        <td>{{ row.item.secondname }}</td>
                        <td>{{ row.item.lastname }}</td>
                        <td>{{ row.item.secondlastname }}</td>
                        <td>{{ row.item.email }}</td>
                        <td>{{ row.item.phone }}</td>
                        <td style="text-align:center">
                            <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn v-bind="attrs" v-on="on" elevation="2" icon @click="addStudent(row.item)"
                                        color="primary" class="mr-1">
                                        <v-icon> mdi-plus</v-icon></v-btn>
                                </template>
                                <span>Seleccionar</span>
                            </v-tooltip>
                        </td>
                    </tr>
                </template>
            </v-data-table>
        </v-sheet>
        <v-expand-transition>
            <v-card class="mx-auto mt-3 transition-fast-in-fast-out v-card--reveal" max-width="100%" outlined v-if="reveal">
                <v-card-text>
                    <p class="body-1 pb-0 mb-2">Alumno seleccionado</p>
                    <v-divider></v-divider>
                    <v-simple-table class="mt-1">
                        <tbody>
                            <tr>
                                <td><strong>DNI:</strong> {{ selectedStudent.dni }} </td>
                                <td><strong>Nombre:</strong> {{ selectedStudent.name }} {{ selectedStudent.secondname }} {{
                                    selectedStudent.lastname }} {{ selectedStudent.secondlastname }}</td>
                                <td><strong>Email:</strong> {{ selectedStudent.email }}</td>
                                <td><strong>Teléfono:</strong> {{ selectedStudent.phone }}</td>
                                <td>
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn v-bind="attrs" v-on="on" elevation="2" icon @click="removeStudent()"
                                                color="error" class="mr-1">
                                                <v-icon> mdi-minus</v-icon></v-btn>
                                        </template>
                                        <span>Eliminar selección </span>
                                    </v-tooltip>
                                </td>
                            </tr>
                        </tbody>
                    </v-simple-table>
                </v-card-text>
            </v-card>
        </v-expand-transition>
    </v-container>
</template>
<script>

import StudentServices from '@/services/student.services'
import CustomSearch from '@/services/util';

export default {
    name: 'SelecteStudent',

    data() {
        return {
            reveal: false,
            loading: true,
            students: [],
            search:'',
            headers: [
                { text: "DNI", align: "start", sortable: true, value: "name" },
                { text: "Nombre", sortable: false },
                { text: "Segundo Nombre", sortable: false },
                { text: "Primer Apellido", sortable: false },
                { text: "Segundo Apellido", sortable: false },
                { text: "Email", sortable: true },
                { text: "Teléfono", sortable: true },
                { text: "Opciones", align: "center", sortable: false },
            ],
            selectedStudent: {
                id: 0,
                dni: 0,
                name: '',
                secondname: '',
                lastname: '',
                secondlastname: '',
                email: '',
                phone: '',
                birthdate: '',
                gender: '',
            }
        }
    },
    mounted() {

        this.getAllStudents()

    },
    methods: {

        getAllStudents() {

            StudentServices.getAllStudents().then((response) => {
                this.students = response.data;
                this.loading = false;

            });

        },

        addStudent(student) {

            this.reveal = false;
            this.selectedStudent.id = student.id;
            this.selectedStudent.dni = student.dni;
            this.selectedStudent.name = student.name;
            this.selectedStudent.secondname = student.secondname;
            this.selectedStudent.lastname = student.lastname;
            this.selectedStudent.secondlastname = student.secondlastname;
            this.selectedStudent.email = student.email;
            this.selectedStudent.phone = student.phone;
            this.selectedStudent.birthdate = student.birthdate;
            this.selectedStudent.gender = student.student;
            this.reveal = true;

            const studentls = this.selectedStudent
            localStorage.setItem('studentls', JSON.stringify(studentls));

        },
        removeStudent() {
            this.selectedStudent = {};
            this.reveal = false;
            localStorage.removeItem('studentls');
        },

        customSearch (value, search, item) {
      return CustomSearch(value, search, item)
     } 
    },

  


}
</script>

<style scoped> .v-sheet.v-card:not(.v-sheet--outlined) {
     box-shadow: 0px 0px 0px -0px rgba(0, 0, 0, 0), 0px 0px 0px 0px rgba(0, 0, 0, 0), 0px 0px 0px 0px rgba(0, 0, 0, 0);
 }
</style>