<template>

        <v-sheet width="100%" elevation="0" outlined rounded>
            <v-row class=" mr-4">
                <v-col cols="6" sm="4">
                    <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details
                        class="pb-3 ml-4"></v-text-field>
                </v-col>
                <v-spacer></v-spacer>
            </v-row>
            <v-divider></v-divider>
            <v-data-table :headers="headers" :items="students" :loading="loading" :search="search"
                :custom-filter="customSearch" loading-text="Cargando información..."
                :items-per-page="30">
                <template v-slot:item="row">
                    <tr>
                        <td>{{ students.indexOf(row.item) + 1 }}</td>
                        <td>{{ row.item.dni }}</td>
                        <td>{{ row.item.name }}</td>
                        <td>{{ row.item.secondname }}</td>
                        <td>{{ row.item.lastname }}</td>
                        <td>{{ row.item.secondlastname }}</td>
                        <td>{{ row.item.email }}</td>
                        <td>{{ row.item.phone }}</td>
                    </tr>
                </template>
            </v-data-table>
        </v-sheet>

</template>
<script>

import StudentServices from '@/services/student.services';
import CustomSearch from '@/services/util';
import eventBus from '@/common/EventBus'

export default {
    name: 'listStudents',

    data() {
        return {
            students: [],
            loading: true,
            search: '',
            headers: [
                { text: "N°", sortable: false },
                { text: "DNI", align: "start", sortable: true, value: "name" },
                { text: "Nombre", sortable: false },
                { text: "Segundo Nombre", sortable: false },
                { text: "Primer Apellido", sortable: false },
                { text: "Segundo Apellido", sortable: false },
                { text: "Email", sortable: true },
                { text: "Teléfono", sortable: true },
                { text: "Opciones", align: "center", sortable: false },
            ],

        }
    },

    mounted() {
        this.getAllStudents();
        eventBus.on('reload-liststudents', () => {
        this.getAllStudents();
    })
    },

    methods: {
        getAllStudents() {
            StudentServices.getAllStudents().then((response) => {
                this.students = response.data;
                this.loading = false;
            });
        },

        customSearch(value, search, item) {
            return CustomSearch(value, search, item)
        }
    },



}
</script>
