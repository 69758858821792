<template>
    <v-navigation-drawer app class="pl-0 pr-0" v-model="drawer" :mini-variant.sync="mini" permanent>
        <v-list>
            <v-list-item class="px-2" link>
                <v-list-item-avatar>
                    <v-icon size="35" color="#005CB9">mdi-account-circle </v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                    <v-list-item-title class="text-h6">
                        {{ currentUser.name }} {{ currentUser.lastname }}
                    </v-list-item-title>
                    <v-list-item-subtitle>{{ currentUser.email }}</v-list-item-subtitle>
                </v-list-item-content>

                <v-list-item-action>
                    <v-btn icon @click.stop="mini = !mini">
                        <v-icon>mdi-chevron-left</v-icon>
                    </v-btn>
                </v-list-item-action>
            </v-list-item>
        </v-list>
        <v-divider></v-divider>
        <v-layout justify-center align-center>
            <v-flex>
                <v-list justify-center align-center>
                    <v-list-item-group color="primary">
                        <v-list-item v-for="(item, i) in items" :key="i" :to="item.url">
                            <v-list-item-icon >
                                <v-icon>{{ item.icon }}</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>{{ item.text }}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>

                        <v-divider class="mb-3"></v-divider>
                        <v-subheader class="ml-2"  v-show="!mini">  Venta en Cuotas </v-subheader>
                      
                     
                        <v-list-item v-for="(item, j) in  items_ventas_cuotas" :key="'a'+j" :to="item.url">
                            <v-list-item-icon >
                                <v-icon>{{ item.icon }}</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>{{ item.text }}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>


                     <!--   <v-divider class="mb-3"></v-divider>
                        <v-subheader class="ml-2" v-show="!mini">  Venta Directa </v-subheader>
                      
                     
                        <v-list-item v-for="(item, x) in items_ventas_directas" :key="'b'+x" :to="item.url">
                            <v-list-item-icon >
                                <v-icon>{{ item.icon }}</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>{{ item.text }}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        -->

                        <v-divider class="mb-3"></v-divider>
                        <v-list-item v-for="(item, x) in items_otros" :key="'b'+x" :to="item.url">
                            <v-list-item-icon >
                                <v-icon>{{ item.icon }}</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>{{ item.text }}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>

                    </v-list-item-group>
                </v-list>
            </v-flex>
        </v-layout>
        <template v-slot:append>
            <v-layout>
                <v-tooltip right>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn color="grey darken-1" dark v-bind="attrs" v-on="on" fab small @click="logOut()">
                            <v-icon dark>
                                mdi-logout
                            </v-icon>
                        </v-btn>
                    </template>
                    <span>Cerrar Sesión</span>
                </v-tooltip>
            </v-layout>
        </template>
    </v-navigation-drawer>
</template>

<script>

export default { 
    name: 'CustomMenu',
    props: {
        Itemselected: { type: Number, default: 0 }
    },
    data: () => ({
        drawer: true,
        mini: false,

        items: [
          //  { text: 'Dashboard', icon: 'mdi-view-dashboard', role: ['adm'], url: '/dashboard' },
            { text: 'Programas', icon: 'mdi-school', role: ['adm', 'user'], url: '/programs' },
            { text: 'Estudiantes', icon: 'mdi-account-school', role: ['adm', 'user'], url: '/students' },
            { text: 'Usuarios', icon: 'mdi-account-multiple', role: ['adm', 'user'], url: '/users' },
        ],
        items_ventas_cuotas:[
            { text: 'Crear venta cuotas', icon: 'mdi-percent-box-outline', role: ['adm', 'user'], url: '/dues' },
            { text: 'Lista de ventas cuotas', icon: 'mdi-invoice-list-outline', role: ['adm', 'user'], url: '/list-dues' },
    ],
        items_ventas_directas:[
            //{ text: 'Crear venta directa', icon: 'mdi-cash', role: ['adm', 'user'], url: '/fddsss' },
            //{ text: 'Lista de ventas directa', icon: 'mdi-invoice-list', role: ['adm', 'user'], url: '/sdsd' },
        ],
        items_otros:[
           // { text: 'Reporte de ventas', icon: 'mdi-file-document-check-outline', role: ['adm', 'user'], url: '/report' },
            //{ text: 'Lista de ventas directa', icon: 'mdi-invoice-list', role: ['adm', 'user'], url: '/sdsd' },
        ]
    }),
    computed: {
        currentUser() {
            return this.$store.state.auth.user;
        },
     
    },
    mounted() {

        if (!this.currentUser) {
            this.$router.push('/login');
        }
    },
    methods: {
        logOut() {
            this.$store.dispatch('auth/logout');
            this.$router.push('/login');
        }
    }


}
</script>
<style scoped>
.v-navigation-drawer {
    box-shadow: 4px 0 12px -10px #888;
}


.nav a {
  font-weight: lighter;
  color: #2c3e50;
}
</style>
