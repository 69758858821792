<template>

<div> 
    <v-btn color="primary" class="mb-5" @click="openDialog()">
      Crear Usuario +
    </v-btn>
    <v-dialog v-model="dialog" max-width="500">
      <v-card>
        <v-card-title class="text-h6"> Crear  un nuevo Usuario </v-card-title>
        <v-divider></v-divider>

        <v-card-text>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-text-field
              v-model="user.name"
              :counter="50"
              :rules="nameRules"
              label="Nombre"
              required
            ></v-text-field>
            <v-text-field
              v-model="user.lastname"
              :counter="50"
              :rules="nameRules"
              label="Apellido"
              required
            ></v-text-field>
            <v-text-field
              v-model="user.email"
              :rules="emailRules"
              label="E-mail"
              required
            ></v-text-field>
            <v-text-field
              v-model="user.passwd"
              :rules="nameRules"
              label="Password"
              required
            ></v-text-field>

            <v-switch
              v-model="switch1"
              :label="`${switch1 ? 'Activo' : 'Inactivo'}`"
            ></v-switch>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="error" small @click="closeModal()">
            <v-icon right dark> mdi-close </v-icon>
            Cancelar
          </v-btn>

          <v-btn color="primary" small @click="validate()">
            Aceptar
            <v-icon right dark> mdi-content-save </v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  
    <v-sheet width="100%" elevation="0" outlined rounded>
      <v-data-table :headers="headers" :items="users" :loading="loading" loading-text="Cargando información...">
        <template v-slot:item="row">
          <tr >
            <td >{{ row.item.name }}</td>
            <td>{{ row.item.lastname }}</td>
            <td>{{ row.item.email }}</td>
            <td>{{ row.item.estado }}</td>
            <td>{{ row.item.createdAt }}</td>
            <td>
              <v-btn elevation="2" icon @click="editUer(row.item)">
                <v-icon>mdi-pencil</v-icon></v-btn
              >
            </td>
          </tr>
        </template>
      </v-data-table>

 </v-sheet>
</div>
</template>

<script>
import userService from "@/services/user.service";
/* eslint-disable */
// eslint-disable-next-line

export default {
  name: "adminUser",
  data() {
    return {
      valid: false,
      dialog: false,
      switch1: true,
      loading:true,
      pagination: {},
      headers: [
        { text: "Nombre", align: "start", sortable: false, value: "name" },
        { text: "Apellido", value: "lastname" },
        { text: "Email", value: "email" },
        { text: "Estado", value: "estado" },
        { text: "Creado el", value: "createdAt" },
        { text: "Editar" },
      ],
      users: [],
      user: {},
      operation: "create",
      emailRules: [
        (v) => !!v || "El correo es requerido",
        (v) => (v && /.+@.+\..+/.test(v)) || "El correo no es valido",
      ],
      nameRules: [
        (v) => !!v || "El campo es requerido",
        (v) =>
          (v && v.length >= 3) || "El campo debe tener más de 3 caracteres",
      ],
    };
  },
  mounted() {
    this.getAllUser();
  },

  methods: {
    editUer(user) {
      this.user = user;
      this.dialog = true;
      this.operation = "update";
    },

    openDialog() {
      this.user = {};
      this.dialog = true;
      this.operation = "create";
    },

    getAllUser() {
      userService.getAllUsers().then((response) => {
        this.users = response.data;
        this.loading=false;
      });
    },

    closeModal() {
      this.user = {};
      this.dialog = false;
    },

    validate() {
      if (this.$refs.form.validate())
       this.createUser();
    },

    createUser() {
      this.user.estado = this.switch1 ? "activo" : "inactivo";

      if (this.operation == "create") {
        userService.postCreateUser(this.user).then((response) => {
          this.getAllUser();
          this.dialog = false;
        });
      } else {
        userService.postEditUser(this.user).then((response) => {
          this.getAllUser();
          this.dialog = false;
        });
      }
    },
  },
};
</script>