<template>
    <Bar
      id="my-chart-id"
      :options="chartOptions"
      :data="chartData"
       height="400px"
    

    />
  </template>
  
  <script>
  import { Bar } from 'vue-chartjs'
  import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js'
  
  ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)
  
  export default {
    name: 'BarChart',
    components: { Bar },
    
    
    data() {
    return {
      chartData: {
        labels: ["Control de Gestión y Business Intelligence", "Control de Gestión y Supply Chain", "Líderes en Tiempos de Cambios", " Gestión de la Felicidad Organizacional ", "Gestión de Equipos de Alto Rendimiento ", "Liderazgo Digital y Gestión de Equipos de Alto Rendimiento", "Marketing Digital y ecommerce", "Marketing Digital y Business Model", "Marketing Digital y Business Intelligence",
          "Estrategias de Negocios Digitales y Business Intelligence", "Negociación y Gestión Comercial", "Estrategias de Ventas y Desarrollo de Equipos de Alto Rendimiento"
        ],
        datasets: [{
          label: 'Bar Chart',
          backgroundColor: [
          '#1C36D9',
            '#7C1CD9',
            '#3F1CD9',
            '#1C72D9',
            '#BA1CD9',
            '#1C36D9',
            '#7C1CD9',
            '#3F1CD9',
            '#1C72D9',
            '#BA1CD9',
            '#1C36D9',
            '#7C1CD9'
          ],
        
          pointBorderColor: '#2554FF',
          data: [12, 19, 3, 5, 2, 3, 20, 3, 5, 6, 2, 1]
        }]
      },
      legend: {
        display: true
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        indexAxis: 'y'
        
      }
    }
    }    
  }
  </script>