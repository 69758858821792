<template>
    <Bar
      id="my-chart-id"
      :options="chartOptions"
      :data="chartData"
       height="400px"
    

    />
  </template>
  
  <script>
  import { Bar } from 'vue-chartjs'
  import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js'
  
  ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)
  
  export default {
    name: 'BarChart',
    components: { Bar },
    props: {
        chartId: {
        type: String,
        default: 'bar-chart'
        },
        datasetIdKey: {
        type: String,
        default: 'label'
        },
        width: {
        type: Number,
        default: 400
        },
        height: {
        type: Number,
        default: 400
        },
        cssClasses: {
        default: '',
        type: String
        },
        styles: {
        type: Object,
        default: () => { }
        },
        plugins: {
        type: Array,
        default: () => []
        }
    },

    data() {
    return {
      chartData: {
        labels: ["Plan 1C-10", "Plan 3C-10", "Plan 5C-10", "Plan 1C-20", "Plan 3C-20", "Plan 5C-20", "Plan 1C-30", "Plan 3C-30", "Plan 4C-30",
          "Plan 2C", "Plan3C", "Plan 5C"
        ],
        datasets: [{
          label: 'Bar Chart',
          backgroundColor: [
            '#1C36D9',
            '#7C1CD9',
            '#3F1CD9',
            '#1C72D9',
            '#BA1CD9',
            '#1C36D9',
            '#7C1CD9',
            '#3F1CD9',
            '#1C72D9',
            '#BA1CD9',
            '#1C36D9',
            '#7C1CD9'
          ],
        
          pointBorderColor: '#2554FF',
          data: [12, 19, 3, 5, 2, 3, 20, 3, 5, 6, 2, 1]
        }]
      },
      legend: {
        display: true
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false
      }
    }
    }    
  }
  </script>