<template>
    <LineChartGenerator    id="my-chart-id"
      :options="chartOptions"
      :data="chartData"
      height="250px"

    
 />
  </template>
  
  <script>

import { Line as LineChartGenerator } from 'vue-chartjs'

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement
} from 'chart.js'

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement
)

export default {
  name: 'LineChart',
  components: {
    LineChartGenerator
  },

  
  data() {
    return {
      chartData: {
        labels: [
          'January',
          'February',
          'March',
          'April',
          'May',
          'June',
          'July'
        ],
        datasets: [
          {
            label: 'Data One',

            data: [40, 39, 10, 40, 39, 80, 40],
            fill: false,
            borderColor: '#1FA3F0',
            backgroundColor: '#1FA3F0',
            borderWidht: '1px'
          }
        ]
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false
      }
    }
  }
}
</script>
