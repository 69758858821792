import api from './api';


class UserService {
  
  getAllUsers() {
    return api.get('/user/all');
  }

  postCreateUser(user){ 
   return api.post("/user/create", {
      name:user.name,
      lastname:user.lastname,
      username:user.email,
      email:user.email,
      password:user.passwd,
      roles:['user','admin'],
      estado:user.estado
    });
  }

  postEditUser(user){ 
    return api.post("/user/edit", {
       id:user.id,
       name:user.name,
       lastname:user.lastname,
       username:user.email,
       email:user.email,
       password:user.passwd,
       roles:['user','admin'],
       estado:user.estado,
       guid: user.guid
     });
   }

}

export default new UserService();
