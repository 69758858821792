import api from "./api";

class ProgramService {
  getAllProgramas() {
    return api.get("/program/all");
  }

  getAllProgramTypes() {
    return api.get("/programtypes/all");
  }

  postCreateProgram(program) {
    return api.post("program/create", {
      name: program.name,
      description: program.description,
      codeProgram: program.codeprogram,
      currency: program.currency,
      country: "PEN",
      price: program.price,
      priceCLP: program.priceCLP,
      programTypeId: program.programTypeId,
      createby: program.createby,
    });
  }
}
export default new ProgramService();
