<template>
    <v-main>
        <v-container class="max-container">
            <CustomMenu></CustomMenu>
            <v-sheet width="100%" elevation="0" outlined rounded>


                <v-card class="mt-5 ml-3 mr-3 mb-5 pl-5 pr-5 pt-5" elevation="0" outlined rounded>
                    <p>Seleccione los parametros para obtener el tipo de reporte</p>
                    <v-row>
                        <v-col cols="12" sm="2">
                            <v-select :items="report_type" label="Seleccione Reporte"></v-select></v-col>
                        <v-col cols="12" sm="3">
                            <v-select :items="programs" label="Seleccione Programa" item-text="name"
                                item-value="id"></v-select>
                        </v-col>
                        <v-col cols="12" sm="2">
                            <v-text-field :counter="10" label="DNI" required></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="2">
                            <v-menu ref="fechadesde" v-model="fechadesde" :close-on-content-click="false"
                                transition="scale-transition" offset-y max-width="290px" min-width="auto">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field v-model="dateFormatted" label="Fecha Inicio" hint="MM/DD/YYYY"
                                        persistent-hint prepend-icon="mdi-calendar" v-bind="attrs"
                                        @blur="date = parseDate(dateFormatted)" v-on="on"></v-text-field>
                                </template>
                                <v-date-picker v-model="datedesde" no-title @input="fechadesde = false"></v-date-picker>
                            </v-menu>
                        </v-col>
                        <v-col cols="12" sm="2">
                            <v-menu ref="fechahasta" v-model="fechahasta" :close-on-content-click="false"
                                transition="scale-transition" offset-y max-width="290px" min-width="auto">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field v-model="dateFormatted" label="Fecha Inicio" hint="MM/DD/YYYY"
                                        persistent-hint prepend-icon="mdi-calendar" v-bind="attrs"
                                        @blur="date = parseDate(dateFormatted)" v-on="on"></v-text-field>
                                </template>
                                <v-date-picker v-model="datehasta" no-title @input="fechahasta = false"></v-date-picker>
                            </v-menu>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" sm="8">
                            <v-text-field :counter="150" label="Ingrese correo donde llegará el reporte" required></v-text-field>
                        </v-col>  
                    </v-row>
                    <v-card-actions>
                      
                        <v-btn color="primary" class="mb-2" @click="createReport()">
            Generar Reporte
        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-sheet>
        </v-container>
    </v-main>
</template>




<script>

import programServices from '@/services/program.services';
import CustomMenu from '@/components/CustomMenu.vue';
export default {

    name: 'report',
    components: {
        CustomMenu: CustomMenu,

    },
    data: () => ({
        report_type: ['Pagos', 'Matrícula', 'Conciliación'],
        programs: [],
        datedesde: null,
        datehasta: null,
        dateFormatted: null,
        fechadesde: false,
        fechahasta: false,

    }),
    mounted() {
        this.getAllPrograms();
    },
    computed: {
        computedDateFormatted() {
            return this.formatDate(this.date)
        },
    },
    watch: {
        date() {
            this.dateFormatted = this.formatDate(this.date)
        },
    },
    methods: {
        getAllPrograms() {
            programServices.getAllProgramas().then((response) => {
                this.programs = response.data;

            });
        },

        formatDate(date) {
            if (!date) return null

            const [year, month, day] = date.split('-')
            return `${month}/${day}/${year}`
        },
        parseDate(date) {
            if (!date) return null

            const [month, day, year] = date.split('/')
            return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
        },

        createReport(){
            alert('ssss');
        }
    },
};
</script>

<style scoped>
.max-container {
    max-width: 95%;
}
</style>