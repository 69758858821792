<template>
    <Doughnut id="my-chart-id" :options="chartOptions" :data="chartData"  height="250px"  />
  </template>
  <script>
  import { Doughnut } from 'vue-chartjs'
  import {
    Chart as ChartJS,
    Title,
    Tooltip,
    Legend,
    ArcElement,
    CategoryScale
  } from 'chart.js'
  ChartJS.register(Title, Tooltip, Legend, ArcElement, CategoryScale)
  export default {
    name: 'DoughnutChart',
    components: {
      Doughnut
    },
    
    data() {
      return {
        chartData: {
          labels: ["Rechazados", "Pagados", "Pendientes"],
          datasets: [{
            
            backgroundColor: [
            '#3F1CD9',
              '#1C72D9',
              '#BA1CD9'
            ],
            data: [5, 150, 20]
          }]
        },
        chartOptions: {
          responsive: true,
          maintainAspectRatio: false
        }
      }
    }
  }
  </script>