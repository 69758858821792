import api from "./api";
import axios from "axios";

class PlanServices {
  getAllPlan() {
    return api.get("/plan/all");
  }

  getPlanByGuid(guid){
    return api.get(`plan/${guid}`)
  }

  postCreatePlan(plan) {
    
    return api.post("plan/create", {
      name: plan.name,
      description: plan.description,
      country: plan.country,
      currency: plan.currency,
      amount: plan.amount,
      dues: plan.dues,
      priceprogram: plan.priceprogram,
      createby: plan.createby,
      programId: plan.programId,
      payerId: plan.payerId,
      discount: plan.discount,
      type_payment:plan.type_payment
    });
  }

  postplanbyguid(guid) {
    return api.post("subscription/checkout/", {
      guid: guid,
    });
  }

  postUpdatePayer(plan) {
    return api.post('subscription/checkoutupdate', {
      programname:plan.programname,
      amount: plan.amount,
      programprice:plan.programprice,
      dni: plan.dni,
      hsid:plan.hsid,
      name: plan.fname,
      secondname: plan.sname,
      lastname: plan.lname,
      secondlastname: plan.lsname,
      email: plan.email,
      phone: plan.phone,
      address: plan.address,
      guid: plan.guidp,
      dnidoc: plan.dnicopy,
      certificatedoc: plan.titulocopy,
      departamento:plan.departamento, 
      provincia:plan.provincia,
      distrito:plan.distrito,
      titulofile: plan.titulofile
    },
    );
  }



  postUploadFile(docfile) {

    const baseURL = process.env.VUE_APP_URL_BASE + 'subscription/fileUploader';
   return axios.post(baseURL, docfile, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  }
}


export default new PlanServices();
