<template>
    <v-main >
<v-container class="max-container">
    <CustomMenu :Itemselected=1></CustomMenu>
    
        <v-btn color="primary" class="mb-2" @click="openDialog()">
            Crear Programa +
        </v-btn>

        <ListProgram :programtypes="programtypes"></ListProgram>
        <CreateProgram :programtypes="programtypes"></CreateProgram>
    
</v-container>
</v-main>
</template>

<script>
import ListProgram from '@/components/program/ListProgram.vue'
import CreateProgram from '@/components/program/CreateProgram.vue'
import CustomMenu from '@/components/CustomMenu.vue'
import programServices from "@/services/program.services";
import eventBus from '../common/EventBus'

export default {
    name: 'Programs',
    components: {

        CustomMenu: CustomMenu,
        ListProgram: ListProgram,
        CreateProgram: CreateProgram
    },

    data() {
        return {
            programtypes: [],
            dialog: false
        };
    },

    methods: {
        getAllTypesPrograms() {
            programServices.getAllProgramTypes().then((response) => {
                this.programtypes = response.data;
            });
        },
        openDialog() {
            eventBus.dispatch('opendialog-createprogram')
        }
    },
    mounted() {
        //borrar aca 

        this.getAllTypesPrograms()
    },

};
</script>
<style scoped>
.max-container{
    max-width: 95%;
}
</style>