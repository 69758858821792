<template>
  <v-main>
    <v-container fluid  class="max-container">
      <CustomMenu ></CustomMenu>  
    <UsersComponent></UsersComponent>
 
  </v-container>
</v-main>
</template>

<script>
import UserService from '../services/user.service';
import UsersComponent from '../components/UsersComponent.vue'
import EventBus from "../common/EventBus";

import CustomMenu from '@/components/CustomMenu.vue';


export default {
  name: 'User',
  components:{
   
    UsersComponent:UsersComponent,
    CustomMenu:CustomMenu
  },

  data() {
    return {
      content: ''
    };
  },
  mounted() {
    UserService.getUserBoard().then(
      response => {
        this.content = response.data;
      },
      error => {
        this.content =
          (error.response && error.response.data && error.response.data.message) ||
          error.message ||
          error.toString();

        if (error.response && error.response.status === 403) {
          EventBus.dispatch("logout");
        }
      }
    );
  }
};
</script>