<template>
    <v-main class="container">
        <div id='card' origin="center center" transition="fade-transition">
            <v-img :src="require('@/assets/img/los-piura-andes.png')"></v-img>
            <div id='upper-side'>
                <!-- Generator: Adobe Illustrator 17.1.0, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->

                <svg version="1.1" id="checkmark" xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" xml:space="preserve">
                    <path d="M131.583,92.152l-0.026-0.041c-0.713-1.118-2.197-1.447-3.316-0.734l-31.782,20.257l-4.74-12.65
	c-0.483-1.29-1.882-1.958-3.124-1.493l-0.045,0.017c-1.242,0.465-1.857,1.888-1.374,3.178l5.763,15.382
	c0.131,0.351,0.334,0.65,0.579,0.898c0.028,0.029,0.06,0.052,0.089,0.08c0.08,0.073,0.159,0.147,0.246,0.209
	c0.071,0.051,0.147,0.091,0.222,0.133c0.058,0.033,0.115,0.069,0.175,0.097c0.081,0.037,0.165,0.063,0.249,0.091
	c0.065,0.022,0.128,0.047,0.195,0.063c0.079,0.019,0.159,0.026,0.239,0.037c0.074,0.01,0.147,0.024,0.221,0.027
	c0.097,0.004,0.194-0.006,0.292-0.014c0.055-0.005,0.109-0.003,0.163-0.012c0.323-0.048,0.641-0.16,0.933-0.346l34.305-21.865
	C131.967,94.755,132.296,93.271,131.583,92.152z" />
                    <circle fill="none" stroke="#ffffff" stroke-width="5" stroke-miterlimit="10" cx="109.486"
                        cy="104.353" r="32.53" />
                </svg>
                <h3 id='status'>
                    Transacción exitosa
                </h3>
            </div>
            <div id='lower-side'>
                <p id='message'>
                    ¡Felicitaciones! Ahora eres parte de www.udeponline.pe, pronto un ejecutivo se pondrá en contacto
                    contigo.

                </p>
                <p id='message'>
                    Si tienes alguna duda o consulta, no dudes en contactarnos por correo  a <a
                        href:mailto="contacto@udeponline.pe">contacto@udeponline.pe</a>
                </p>

                <v-divider></v-divider>


                <div class="pt-3  text-left" >
                    <h4 class="pb-3" >Información de la compra</h4>
                   <p>Código autorización :   <span class="font-weight-bold"> {{ id }}</span></p>
                   <p>Programa : <span class="font-weight-bold"> {{ plan.programname }} </span></p>
                   <p>Valor del programa : <span class="font-weight-bold"> {{ plan.programprice}} </span></p>
                   <p>Valor de la cuota : <span class="font-weight-bold"> {{ plan.amount}} </span></p>
                   <p>Cantidad de cuotas : <span class="font-weight-bold"> {{ plan.dues}} </span></p>

                </div>

                <a href="https://www.udeponline.pe" id="contBtn">Volver al sitio</a>
            </div>
        </div>


    </v-main>
</template>

<script>
import PlanServices from '@/services/plan.services'
export default {
    name: 'SuccessCheckOut',
    data() {
        return {
            id: this.$route.query.gd,
            plan: {
                programname: '',
                programdesc: '',
                programprice: '',
                dues: '',
                amount: '',
                dni: '',
                fname: '',
                lname: '',
                sname: '',
                lsname: '',
                email: '',
                phone: '',
                address: '',
                guidp: '',
                dnicopy: '',
                titulocopy: ''
            },
        }
    },

    created() {
        this.getDataPlan()
    },
    methods: {
        getDataPlan() {
            PlanServices.postplanbyguid(this.id).then((response) => {
                if (response.data) {
                    this.plan.programname = response.data.program.name,
                        this.plan.programdesc = response.data.description,
                        this.plan.dues = response.data.dues,
                        this.plan.amount = Math.floor( response.data.amount),
                        this.plan.programprice = response.data.priceprogram,
                        this.plan.dni = response.data.payer.dni,
                        this.plan.fname = response.data.payer.name,
                        this.plan.sname = response.data.payer.secondname,
                        this.plan.lname = response.data.payer.lastname,
                        this.plan.lsname = response.data.payer.secondlastname,
                        this.plan.email = response.data.payer.email,
                        this.plan.phone = response.data.payer.phone,
                        this.plan.address = response.data.payer.address,
                        this.plan.guidp = response.data.payer.guid
                    this.plantoken = this.uri_base_dlocal + response.data.plan_token
                }
            })
        }

    }

}
</script>

<style scoped>
.container {
    /*
    background: url('@/assets/img/EdificioE.jpg');
    background-repeat: no-repeat;
    background-size: cover;
*/
    min-height: 100%;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0.75)), url('@/assets/img/EdificioE.jpg');
    background-size: cover;

}




body {
    background: #1488EA;
}

#card {
    position: relative;
    top: 30px;
    width: 550px;
    display: block;
    margin: auto;
    text-align: center;
    font-family: 'Source Sans Pro', sans-serif;
}

#upper-side {
    padding: 2em;
    background-color: #005cb9;
    display: block;
    color: #fff;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
}

#checkmark {
    font-weight: lighter;
    fill: #fff;
    margin: -4em auto auto 90px;
}

#status {
    font-weight: lighter;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-size: 1em;
    margin-top: -.2em;
    margin-bottom: 0;
}

#lower-side {
    padding: 2em 2em 5em 2em;
    background: #fff;
    display: block;
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
}

#message {
    margin-top: -.5em;
    color: #54565b;
    letter-spacing: 1px;
    text-align: start;
}

#contBtn {
    position: relative;
    top: 1.5em;
    text-decoration: none;
    background: #00467a;
    color: #fff;
    margin: auto;
    padding: .8em 3em;
    -webkit-box-shadow: 0px 15px 30px rgba(50, 50, 50, 0.21);
    -moz-box-shadow: 0px 15px 30px rgba(50, 50, 50, 0.21);
    box-shadow: 0px 15px 30px rgba(50, 50, 50, 0.21);
    border-radius: 25px;
    -webkit-transition: all .4s ease;
    -moz-transition: all .4s ease;
    -o-transition: all .4s ease;
    transition: all .4s ease;
}

#contBtn:hover {
    -webkit-box-shadow: 0px 15px 30px rgba(50, 50, 50, 0.41);
    -moz-box-shadow: 0px 15px 30px rgba(50, 50, 50, 0.41);
    box-shadow: 0px 15px 30px rgba(50, 50, 50, 0.41);
    -webkit-transition: all .4s ease;
    -moz-transition: all .4s ease;
    -o-transition: all .4s ease;
    transition: all .4s ease;
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}
</style>