<template>
    <Pie     id="my-chart-id"
      :options="chartOptions"
      :data="chartData"
       height="400px"
     />
  </template>
  <script>
  import { Pie } from 'vue-chartjs'
  import {
    Chart as ChartJS,
    Title,
    Tooltip,
    Legend,
    ArcElement,
    CategoryScale
  } from 'chart.js'
  ChartJS.register(Title, Tooltip, Legend, ArcElement, CategoryScale)
  export default {
    name: 'PieChart',
    components: {
      Pie
    },
    
    data() {
      return {
        chartData: {
          labels: ["Plan 5C-10", "Plan 1C", "Plan 5C-30", "Plan 2C-30",],
          datasets: [{
            backgroundColor: [
            '#09CC95',
            '#1FA3F0',
            '#7C1CD9',
            '#1C72D9'
             
            ],
            data: [1000, 500, 1500, 1000]
          }]
        },
        chartOptions: {
          responsive: true,
          maintainAspectRatio: false
        }
      }
    }
  }
  </script>