<template>
  <v-container>
    <v-form ref="form" v-model="valid" lazy-validation class="pt-0 pl-3 pr-3 pb-3 mt-2">

      <v-card class="mx-auto pl-3 pr-3 mt-6" outlined>

        <v-card-subtitle> <strong style="color:blue">1.</strong> Detalles del pago, corresponde al
          precio total del programa</v-card-subtitle>
        <v-row>
          <v-col cols="12" sm="2">
            <v-label>País</v-label>
            <v-select v-model="selectedCurrency" :items="pais" outlined required item-text="name" item-value="name"
              dense :rules="[v => !!v || 'Debe seleccionar el país']" @change="changePais(selectedCurrency)"></v-select>
          </v-col>
          <v-col cols="12" sm="2">
            <v-label>% de Descuento</v-label>
            <v-select v-model="selectedDescuento" :items="descuentos" outlined required item-text="name"
              item-value="name" dense @change="changeDescuento(selectedDescuento)"></v-select>
          </v-col>
          <v-col cols="12" sm="5">
            <v-label>Precio total del programa en : {{ plan.currency }}</v-label>
            <v-text-field v-model="priceprogramSelected" :counter="6" required outlined dense disabled
              type="number"></v-text-field>
          </v-col>
          <v-col cols="12" sm="3">
            <v-label>Tipo de pago</v-label>
            <v-select v-model="selectedTipoPago" :items="tipo_pago" outlined required item-text="name" item-value="name"
              dense :rules="[v => !!v || 'Debe seleccionar el tipo de pago']"
              @change="changeTipoPago(selectedTipoPago)" ></v-select>
          </v-col>
        </v-row>
      </v-card>
      <v-card class="mx-auto pl-3 pr-3 mt-6" outlined>
        <v-card-subtitle> <strong style="color:blue">2.</strong> Cuotas y monto del cobro
          mensual</v-card-subtitle>
        <v-row>
          <v-col cols="12" sm="2">
            <v-label>Cuotas</v-label>
            <v-select v-model="plan.dues" :items="cuotas" outlined required item-text="name" item-value="name" dense
              id="dues_select" :rules="[v => !!v || 'Debe seleccionar la cantidad de cuotas']"
              @change="calculaCobroMensual()" :disabled="disabledDues"></v-select>
          </v-col>
          <v-col cols="12" sm="10">
            <v-label>Precio de cobro mensual en : {{ plan.currency }} </v-label>
            <v-text-field v-model="plan.amount" :counter="80" required outlined dense disabled
              :v-text="plan.amount"></v-text-field>
          </v-col>
        </v-row>

        <v-card-subtitle> <strong style="color:blue">3.</strong>Descripción del pago</v-card-subtitle>
        <p>{{ createdDrescription }}</p>
      </v-card>

      <v-btn block class="mt-3 pt-5 pb-5" dark color="primary" @click="validate()">
        Generar Link de pago
      </v-btn>
    </v-form>


    <v-row justify="center">
      <v-dialog v-model="dialog" persistent max-width="800">
        <v-toolbar color="primary" dark class="mb-0" stick>
          <v-toolbar-title>Detalle de la venta en cuotas</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn v-if="showUrl" icon dark @click="dialog = closeall()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card class="mx-auto " max-width="800" :loading="loading">
          <div v-if="!showUrl">
            <v-list subheader three-line>
              <v-list-item>
                <v-list-item-content class="mt-2">
                  <v-list-item-title>
                    <v-icon class="mb-1 mr-1" color="primary">mdi-account-school</v-icon> Información del
                    Estudiante</v-list-item-title>
                  <v-divider></v-divider>
                  <div>
                    <v-row no-gutters>
                      <v-col cols="12" sm="2">
                        <v-list-item two-line>
                          <v-list-item-content>
                            <v-list-item-title>DNI</v-list-item-title>
                            <v-list-item-subtitle>{{ student.dni }}</v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </v-col>
                      <v-col cols="12" sm="4">
                        <v-list-item two-line>
                          <v-list-item-content>
                            <v-list-item-title>Nombre</v-list-item-title>
                            <v-list-item-subtitle>{{ student.name }}</v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </v-col>
                      <v-col cols="12" sm="3">
                        <v-list-item two-line>
                          <v-list-item-content>
                            <v-list-item-title> Email</v-list-item-title>
                            <v-list-item-subtitle>{{ student.email }}</v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </v-col>
                      <v-col cols="12" sm="3">
                        <v-list-item two-line>
                          <v-list-item-content>
                            <v-list-item-title> Télefono</v-list-item-title>
                            <v-list-item-subtitle>{{ student.phone }}</v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </v-col>
                    </v-row>
                  </div>

                  <div class="mt-4">
                    <v-list-item-title class="font-weight-medium"> <v-icon class="mb-1 mr-1"
                        color="primary">mdi-school</v-icon> Información del
                      Programa </v-list-item-title>
                    <v-divider></v-divider>
                    <div class="font-weight-normal">

                    </div>
                    <v-row no-gutters>
                      <v-col cols="12" sm="5">
                        <v-list-item two-line>
                          <v-list-item-content>
                            <v-list-item-title>Nombre</v-list-item-title>
                            <v-list-item-subtitle>{{ program.name }} </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </v-col>
                      <v-col cols="12" sm="3">
                        <v-list-item two-line>
                          <v-list-item-content>
                            <v-list-item-title>Código</v-list-item-title>
                            <v-list-item-subtitle>{{ program.codeProgram }}</v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </v-col>
                      <v-col cols="12" sm="4">
                        <v-list-item two-line>
                          <v-list-item-content>
                            <v-list-item-title> Tipo de Programa</v-list-item-title>
                            <v-list-item-subtitle>{{ program.programType }} </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </v-col>

                    </v-row>
                  </div>


                  <div class="mt-6">
                    <v-list-item-title> <v-icon class="mb-1 mr-1" color="primary">mdi-credit-card</v-icon> Información
                      del
                      Pago</v-list-item-title>
                    <v-divider></v-divider>
                    <div class="font-weight-regular">
                      <v-row no-gutters>
                        <v-col cols="12" sm="12">
                          <v-list-item two-line>
                            <v-list-item-content>
                              <v-list-item-title>Nombre del Plan </v-list-item-title>
                              <v-list-item-subtitle>{{ plan.name }} </v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                        </v-col>


                        <v-col cols="12" sm="2">
                          <v-list-item two-line>
                            <v-list-item-content>
                              <v-list-item-title> Moneda</v-list-item-title>
                              <v-list-item-subtitle>{{ plan.currency }} </v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                        </v-col>
                        <v-col cols="12" sm="3">
                          <v-list-item two-line>
                            <v-list-item-content>
                              <v-list-item-title> Precio total Programa</v-list-item-title>
                              <v-list-item-subtitle>{{ plan.priceprogram }} </v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                        </v-col>
                        <v-col cols="12" sm="2">
                          <v-list-item two-line>
                            <v-list-item-content>
                              <v-list-item-title> Cuotas</v-list-item-title>
                              <v-list-item-subtitle> {{ plan.dues }} </v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                        </v-col>

                        <v-col cols="12" sm="2">
                          <v-list-item two-line>
                            <v-list-item-content>
                              <v-list-item-title> Precio Cuota</v-list-item-title>
                              <v-list-item-subtitle>{{ plan.amount }} </v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                        </v-col>
                        <v-col cols="12" sm="3">
                          <v-list-item two-line>
                            <v-list-item-content>
                              <v-list-item-title> Tipo de Pago</v-list-item-title>
                              <v-list-item-subtitle>{{ plan.type_payment }} </v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                        </v-col>

                      </v-row>
                    </div>
                  </div>

                </v-list-item-content>
              </v-list-item>
            </v-list>
            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="red darken-1" dark @click="dialog = false" :disabled="loading">
                Cancelar
              </v-btn>

              <v-btn color="primary" dark @click="createUrl()" :loading="loading">
                Crear plan
              </v-btn>
            </v-card-actions>
          </div>
          <div v-else>
            <v-list-item>
              <v-list-item-content class="mt-2">
                <v-list-item-title>
                  <v-icon class="mb-1 mr-1" color="primary">mdi-invoice-text-outline</v-icon> Plan : {{ plan.name }}
                </v-list-item-title>
                <v-divider></v-divider>

                <div style='background: #f9fafb; border-radius: 10px; height: 80px;  margin-top: 10px;'>
                  <v-row no-gutters class="mt-2">



                    <v-col cols="12" sm="2">
                      <v-list-item two-line>
                        <v-list-item-content style="border-left: 1px solid #0066ff; padding-left: 10px;">
                          <v-list-item-title> Moneda</v-list-item-title>
                          <v-list-item-subtitle>{{ plan.currency }} </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                    <v-col cols="12" sm="4">
                      <v-list-item two-line>
                        <v-list-item-content style="border-left: 1px solid #0066ff; padding-left: 10px;">
                          <v-list-item-title> Precio del Programa</v-list-item-title>
                          <v-list-item-subtitle>{{ plan.priceprogram }} </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                    <v-col cols="12" sm="2">
                      <v-list-item two-line>
                        <v-list-item-content style="border-left: 1px solid #0066ff; padding-left: 10px;">
                          <v-list-item-title> Cuotas</v-list-item-title>
                          <v-list-item-subtitle> {{ plan.dues }} </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>

                    <v-col cols="12" sm="4">
                      <v-list-item two-line>
                        <v-list-item-content style="border-left: 1px solid #0066ff; padding-left: 10px;">
                          <v-list-item-title> Precio Cuota</v-list-item-title>
                          <v-list-item-subtitle>{{ plan.amount }} </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>

                  </v-row>

                </div>
                <v-row no-gutters>
                  <p class="mt-5 font-weight-medium">Copia y pega este link</p>
                  <v-col sm="10">

                    <v-text-field prepend-inner-icon="mdi-link" outlined dense disabled
                      v-model="urlCheckOut"></v-text-field>
                  </v-col>
                  <v-col sm="2">
                    <v-btn color="light-blue darken-2" dark style="margin-left: -33px; margin-top:1.5px;"
                      @click="copylink()">
                      <v-icon left>
                        mdi-content-copy
                      </v-icon>
                      {{ txtboton }}
                    </v-btn>
                  </v-col>

                </v-row>
              </v-list-item-content>
            </v-list-item>
          </div>
        </v-card>
      </v-dialog>
    </v-row>
  </v-container>
</template>

<script>


/* eslint-disable */
import eventBus from '@/common/EventBus'
import planServices from '@/services/plan.services'


export default {

  name: "CreatePlans",
  props: {
    programtypes: { type: [], default: 0 },
  },

  data() {
    return {
      showUrl: false,
      valid: false,
      dialog: false,
      loading: false,
      progress: true,
      urlCheckOut: '',
      txtboton: ' Copiar Link',
      selectedCurrency: '',
      selectedTipoPago: '',
      disabledDues:false,
      pais: [{ name: 'Chile', codcurrency: 'CLP' }, { name: 'Perú', codcurrency: 'SOL' }],
      tipo_pago: [{ name: 'DLOCAL', valor: 'DLOCAL' }, { name: 'OC', valor: 'OC' }],
      cuotas: [1, 2, 3, 4, 5, 6],
      descuentos: [0, 5, 10, 15, 20, 25, 30,40],
      selectedDescuento: 0,
      priceprogramSelected: 0,
      plan: {
        name: '',
        description: '',
        country: '',
        currency: '',
        amount: 0,
        priceprogram: 0,
        dues: 0,
        createby: 0,
        payerId: 0,
        programId: 0,
        discount: 0,
        type_payment: ''
      },
      student: {},
      program: {},

      // rules
      nameRules: [(v) => !!v || "El campo es requerido",
      (v) => (v && v.length >= 5) || "El campo debe tener más de 5 caracteres",],
      max3caracter: [
        (v) => !!v || "El campo es requerido",
        (v) =>
          (v && v.length >= 3) || "El campo debe tener más de 3 caracteres",
      ],
      max2caracter: [
        (v) => !!v || "El campo es requerido",
        (v) =>
          (v && v.length >= 2) || "El campo debe tener más de 3 caracteres",
      ],


    }
  },
  mounted() {
    eventBus.on('opendialog-createplan', () => {
      this.dialog = true
    });


    eventBus.on('set-create-program', () => {

      this.student = {};
      this.program = {};
      this.student = JSON.parse(localStorage.getItem('studentls'));
      this.program = JSON.parse(localStorage.getItem('programls'));
      this.selectedCurrency = ''
      this.priceprogramSelected = 0
      this.selectedDescuento = 0
      this.plan.dues = 0
      this.plan.amount = 0
    });




  },
  methods: {

    closeDialog() {
      this.dialog = false
    },
    validate() {
      if (this.$refs.form.validate())
        this.reviewPay();
    },

    changePais(val) {
      this.plan.country = val == 'Chile' ? 'CL' : 'PE';
      this.plan.currency = val == 'Chile' ? 'CLP' : 'PEN';
      this.priceprogramSelected = val == 'Chile' ? this.program.priceCLP : this.program.price;
      this.selectedDescuento = 0
      this.plan.dues = this.plan.dues != 0 ? 0 : this.plan.dues;
      this.plan.amount = this.plan.amount != 0 ? 0 : this.plan.amount;
    },

    changeTipoPago(val) {

      
      this.plan.type_payment = val;
      if (val == 'OC') {
        this.plan.dues = 1
        this.calculaCobroMensual()
        this.disabledDues = true;
      }
      else {
        this.plan.dues = 0;
        this.disabledDues = false;
        this.plan.amount = 0;
        

      }

    },


    changeDescuento(dscto) {

      this.plan.dues = this.plan.dues != 0 ? 0 : this.plan.dues;
      this.plan.amount = this.plan.amount != 0 ? 0 : this.plan.amount;
      this.selectedTipoPago = '';

      /* eslint-disable */
      const pricebase = (this.plan.country) == 'CL' ? this.program.priceCLP : this.program.price;



      if (dscto > 0) {
        this.priceprogramSelected = pricebase - ((pricebase * dscto) / 100)
      } else {

        this.priceprogramSelected = (this.plan.country) == 'CL' ? this.program.priceCLP : this.program.price;
      }
    },

    calculaCobroMensual() {
      this.plan.amount = (Number(this.priceprogramSelected) / Number(this.plan.dues)).toFixed(2)



    },

    reviewPay() {

      this.plan.discount = this.selectedDescuento;
      this.plan.name = this.program.name + ' - ' + this.student.dni;
      this.plan.payerId = this.student.id;
      this.plan.programId = this.program.id;
      this.plan.priceprogram = this.priceprogramSelected;
      this.dialog = true;
      this.plan.description = this.createdDrescription;


    },

    // Servicio que crear el plan 
    createUrl() {
      this.loading = true;
      this.plan.createby = this.$store.state.auth.user.id;
      this.plan.type_payment = this.selectedTipoPago;
      planServices.postCreatePlan(this.plan).then(response => {
        if (response)
          this.loading = false
        this.showUrl = true;
        this.urlCheckOut = process.env.VUE_APP_URL_CHECKOUT + response.data.guid;
      },
        error => {
          console.log(error, 'error al crear plan')
        })
    },

    copylink() {
      navigator.clipboard.writeText(this.urlCheckOut);
      this.txtboton = 'Copiado...'
    },

    closeall() {
      this.dialog = false;
      localStorage.removeItem('studentls');
      localStorage.removeItem('programls');
      this.$router.go();
    }

  },
  computed: {
    createdDrescription: function () {
      return 'Plan de estudios en ' + this.plan.dues + ' cuotas con un ' + this.selectedDescuento + '% de descuento';
    }
  }

}
</script>

<style scoped>
.v-label {
  color: #666161;
  font-weight: 400;
  font-size: 11pt
}

.v-card__subtitle {
  padding: 0;
  padding-top: 15px;
  padding-bottom: 20px;

}

.v-text-field--outlined>>>fieldset {
  border-color: #dfe5ef
}

.v-application .transparent {
  border-color: rgb(255, 0, 0) !important;
}
</style>
