class validacionesPeru {

    validaDNI(dni){
       var onlyNumbersDNI = dni.toString().replace(/\D/g, '');
        let ex_regular_dni = /^\d{7,8}(?:[-\s]\d{4})?$/;
        if(ex_regular_dni.test(onlyNumbersDNI) == true && !(/[a-zA-Z]/g.test(dni)) && onlyNumbersDNI === dni.replace(/(\d)[\s.]+(?=\d)/g, '$1')){
          
            return true;
        }else{
          
            return false;
        }
    }

}



export default new validacionesPeru();